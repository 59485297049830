import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../layout/Sidebar';

import AuthContext from '../../context/auth/authContext';
import InsuranceContext from '../../context/insurance/insuranceContext';

const ExitedInsurances = (props) => {
  const authContext = useContext(AuthContext);
  const insuranceContext = useContext(InsuranceContext);

  const {
    isAuthenticated,
    logout,

    user,
    setCurrent,

    loaduser,
  } = authContext;
  const {
    getExitedInsurance,
    deleteInsurance,

    searchExitInsurance,

    insurance_exit,
    search,
    PAGE_NUMBER,
  } = insuranceContext;

  //pagination
  const [register, setRegister] = useState({
    searchField: '',
  });
  let { searchField } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,

      //[e.target.SeatNumber]: e.target.value.split(","),
    });

  const [page, setPage] = useState(PAGE_NUMBER);

  //increment page
  const scrollToEnd = () => {
    setPage(page + 1);
  };

  // const unique = insurance.filter((item, index) => insurance.indexOf(item) === index);

  //when scrolled
  window.onscroll = function () {
    //check if paged is scrolled
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  //end of pagination

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/exited');
    }
    loaduser();
    getExitedInsurance(page);

    //searchExitInsurance(searchField);

    // eslint-disable-next-line
  }, [isAuthenticated, props.history, page]);
  const onLogout = () => {
    logout();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    searchExitInsurance(searchField);
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form
                onSubmit={onSubmit}
                class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'
              >
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                    name='searchField'
                    value={searchField}
                    onChange={onChange}
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='submit'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <li class='nav-item dropdown no-arrow d-sm-none'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='searchDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-search fa-fw'></i>
                  </a>

                  <div
                    class='dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in'
                    aria-labelledby='searchDropdown'
                  >
                    <form
                      class='form-inline mr-auto w-100 navbar-search'
                      onSubmit={onSubmit}
                    >
                      <div class='input-group'>
                        <input
                          type='text'
                          class='form-control bg-light border-0 small'
                          placeholder='Search for...'
                          aria-label='Search'
                          aria-describedby='basic-addon2'
                          name='searchField'
                          value={searchField}
                          onChange={onChange}
                        />
                        <div class='input-group-append'>
                          <button class='btn btn-primary' type='submit'>
                            <i class='fas fa-search fa-sm'></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-lg-12'></div>
              </div>
              <div class='card shadow mb-4'>
                <div class='card-header py-3'>
                  <b>EXITED INSURANCES</b>
                </div>

                {user && user.role !== 'admin' ? (
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table
                        class='table table-bordered'
                        id='dataTable'
                        width='100%'
                        cellspacing='0'
                      >
                        <thead>
                          <tr>
                            <th scope='col'>Created </th>
                            <th scope='col'>Insured</th>
                            <th scope='col'>Policy Number</th>

                            <th scope='col'>Mv Registration</th>
                            <th scope='col'>View </th>

                            <th scope='col'>Renew</th>
                          </tr>
                        </thead>
                        <tbody>
                          {insurance_exit && searchField === ''
                            ? insurance_exit.map((insurance) => (
                                <Fragment>
                                  <tr>
                                    <td>
                                      <i
                                        data-toggle='collapse'
                                        id={`row${insurance.id}`}
                                        data-target={`.row${insurance.id}`}
                                        class='clickable fas fa-plus sm icon'
                                      ></i>
                                      {new Date(
                                        insurance.createdAt
                                      ).toLocaleDateString('zh-Hans-CN')}
                                    </td>
                                    <td>{insurance.insured_name}</td>

                                    <td>{insurance.policy_number}</td>
                                    {/* <td>{insurance.inception_date}</td>
                      <td>{insurance.expiry_date}</td> */}
                                    <td>{insurance.mv_registration}</td>

                                    <td>
                                      <Link to='/view'>
                                        <button
                                          className='btn btn-info btn-sm rounded-0'
                                          onClick={() => setCurrent(insurance)}
                                        >
                                          <i className='fa fa-eye '></i>
                                        </button>
                                      </Link>
                                    </td>

                                    <td>
                                      <Link to='/renew'>
                                        <button
                                          className='btn btn-primary btn-sm rounded-0'
                                          onClick={() => setCurrent(insurance)}
                                        >
                                          <i className='fas fa-undo'></i>
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr class={`collapse row${insurance.id}`}>
                                    <td>
                                      <ul class='list-group list-group-flush'>
                                        <li class='list-group-item'>
                                          Policy No: {insurance.policy_number}
                                        </li>
                                        <li class='list-group-item'>
                                          Inception Date:{' '}
                                          {new Date(
                                            insurance.inception_date
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Expiry Date:{' '}
                                          {new Date(
                                            insurance.expiry_date
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Mv Registration:{' '}
                                          {insurance.mv_registration}
                                        </li>
                                        <li class='list-group-item'>
                                          Tonnage: {insurance.tonnage}
                                        </li>
                                        <li class='list-group-item'>
                                          Insurer: {insurance.insurer}
                                        </li>
                                        <li class='list-group-item'>
                                          Date of Issue:{' '}
                                          {new Date(
                                            insurance.createdAt
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Amount: {insurance.amount}
                                        </li>
                                        <li class='list-group-item'>
                                          Contact: {insurance.phone}
                                        </li>

                                        <li className='list-group-item'>
                                          Action:
                                          <Link to='/view'>
                                            <button
                                              className='btn btn-info btn-sm rounded-0'
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                            >
                                              <i className='fa fa-eye '></i>
                                            </button>
                                          </Link>
                                        </li>
                                        <li className='list-group-item'>
                                          <Link to='/renew'>
                                            <button
                                              className='btn btn-primary btn-sm rounded-0'
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                            >
                                              <i className='fas fa-undo'></i>
                                            </button>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </Fragment>
                              ))
                            : search &&
                              search.map((insurance) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td>
                                        <i
                                          data-toggle='collapse'
                                          id={`row${insurance.id}`}
                                          data-target={`.row${insurance.id}`}
                                          class='clickable fas fa-plus sm icon'
                                        ></i>
                                        {new Date(
                                          insurance.createdAt
                                        ).toLocaleDateString('zh-Hans-CN')}
                                      </td>
                                      <td>{insurance.insured_name}</td>

                                      <td>{insurance.policy_number}</td>
                                      {/* <td>{insurance.inception_date}</td>
                          <td>{insurance.expiry_date}</td> */}
                                      <td>{insurance.mv_registration}</td>

                                      <td>
                                        <Link to='/view'>
                                          <button
                                            className='btn btn-info btn-sm rounded-0'
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                          >
                                            <i className='fa fa-eye '></i>
                                          </button>
                                        </Link>
                                      </td>

                                      <td>
                                        <Link to='/renew'>
                                          <button
                                            className='btn btn-primary btn-sm rounded-0'
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                          >
                                            <i className='fas fa-undo'></i>
                                          </button>
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr class={`collapse row${insurance.id}`}>
                                      <td>
                                        <ul class='list-group list-group-flush'>
                                          <li class='list-group-item'>
                                            Policy No: {insurance.policy_number}
                                          </li>
                                          <li class='list-group-item'>
                                            Inception Date:{' '}
                                            {new Date(
                                              insurance.inception_date
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Expiry Date:{' '}
                                            {new Date(
                                              insurance.expiry_date
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Mv Registration:{' '}
                                            {insurance.mv_registration}
                                          </li>
                                          <li class='list-group-item'>
                                            Tonnage: {insurance.tonnage}
                                          </li>
                                          <li class='list-group-item'>
                                            Insurer: {insurance.insurer}
                                          </li>
                                          <li class='list-group-item'>
                                            Date of Issue:{' '}
                                            {new Date(
                                              insurance.createdAt
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Amount: {insurance.amount}
                                          </li>
                                          <li class='list-group-item'>
                                            Contact: {insurance.phone}
                                          </li>

                                          <li className='list-group-item'>
                                            Action:
                                            <Link to='/view'>
                                              <button
                                                className='btn btn-info btn-sm rounded-0'
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                              >
                                                <i className='fa fa-eye '></i>
                                              </button>
                                            </Link>
                                          </li>
                                          <li className='list-group-item'>
                                            <Link to='/renew'>
                                              <button
                                                className='btn btn-primary btn-sm rounded-0'
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                              >
                                                <i className='fas fa-undo'></i>
                                              </button>
                                            </Link>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table
                        class='table table-bordered'
                        id='dataTable'
                        width='100%'
                        cellspacing='0'
                      >
                        <thead>
                          <tr>
                            <th scope='col'>Created </th>
                            <th scope='col'>Insured</th>
                            <th scope='col'>Policy Number</th>

                            <th scope='col'>Mv Registration</th>
                            <th scope='col'>View </th>
                            <th scope='col'>Edit</th>
                            <th scope='col'>Delete</th>
                            <th scope='col'>Renew</th>

                            <th scope='col'>Print</th>
                          </tr>
                        </thead>
                        <tbody>
                          {insurance_exit && searchField === ''
                            ? insurance_exit.map((insurance) => (
                                <Fragment>
                                  <tr>
                                    <td>
                                      <i
                                        data-toggle='collapse'
                                        id={`row${insurance.id}`}
                                        data-target={`.row${insurance.id}`}
                                        class='clickable fas fa-plus sm icon'
                                      ></i>
                                      {new Date(
                                        insurance.createdAt
                                      ).toLocaleDateString('zh-Hans-CN')}
                                    </td>
                                    <td>{insurance.insured_name}</td>

                                    <td>{insurance.policy_number}</td>
                                    {/* <td>{insurance.inception_date}</td>
                      <td>{insurance.expiry_date}</td> */}
                                    <td>{insurance.mv_registration}</td>

                                    <td>
                                      <Link to='/view'>
                                        <button
                                          className='btn btn-info btn-sm rounded-0'
                                          onClick={() => setCurrent(insurance)}
                                        >
                                          <i className='fa fa-eye '></i>
                                        </button>
                                      </Link>
                                    </td>

                                    <td>
                                      <Link to='/edit'>
                                        <button
                                          className='btn btn-warning btn-sm rounded-0'
                                          onClick={() => setCurrent(insurance)}
                                        >
                                          <i className='fas fa-pencil-alt'></i>
                                        </button>
                                      </Link>
                                    </td>
                                    <td>
                                      <button
                                        className='btn btn-danger btn-sm rounded-0'
                                        onClick={() => {
                                          if (
                                            window.confirm(
                                              'Do you want to remove Insurance?'
                                            )
                                          ) {
                                            deleteInsurance(insurance.id);
                                          }
                                        }}
                                      >
                                        <i className='fas fa-trash'></i>
                                      </button>
                                    </td>
                                    <td>
                                      <Link to='/renew'>
                                        <button
                                          className='btn btn-primary btn-sm rounded-0'
                                          onClick={() => setCurrent(insurance)}
                                        >
                                          <i className='fas fa-undo'></i>
                                        </button>
                                      </Link>
                                    </td>

                                    <td>
                                      <Link to='/insurance/print'>
                                        <button
                                          onClick={() => setCurrent(insurance)}
                                          type='submit'
                                          className='btn btn-secondary btn-sm rounded-0'
                                        >
                                          <i className='fas fa-print'></i>
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                  <tr class={`collapse row${insurance.id}`}>
                                    <td>
                                      <ul class='list-group list-group-flush'>
                                        <li class='list-group-item'>
                                          Policy No: {insurance.policy_number}
                                        </li>
                                        <li class='list-group-item'>
                                          Inception Date:{' '}
                                          {new Date(
                                            insurance.inception_date
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Expiry Date:{' '}
                                          {new Date(
                                            insurance.expiry_date
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Mv Registration:{' '}
                                          {insurance.mv_registration}
                                        </li>
                                        <li class='list-group-item'>
                                          Tonnage: {insurance.tonnage}
                                        </li>
                                        <li class='list-group-item'>
                                          Insurer: {insurance.insurer}
                                        </li>
                                        <li class='list-group-item'>
                                          Date of Issue:{' '}
                                          {new Date(
                                            insurance.createdAt
                                          ).toLocaleDateString('zh-Hans-CN')}
                                        </li>
                                        <li class='list-group-item'>
                                          Amount: {insurance.amount}
                                        </li>
                                        <li class='list-group-item'>
                                          Contact: {insurance.phone}
                                        </li>

                                        <li className='list-group-item'>
                                          Action:
                                          <Link to='/view'>
                                            <button
                                              className='btn btn-info btn-sm rounded-0'
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                            >
                                              <i className='fa fa-eye '></i>
                                            </button>
                                          </Link>
                                          <Link to='/edit'>
                                            <button
                                              className='btn btn-warning btn-sm rounded-0'
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                            >
                                              <i className='fas fa-pencil-alt'></i>
                                            </button>
                                          </Link>
                                          <button
                                            className='btn btn-danger btn-sm rounded-0'
                                            onClick={() => {
                                              if (
                                                window.confirm(
                                                  'Do you want to remove Insurance?'
                                                )
                                              ) {
                                                deleteInsurance(insurance.id);
                                              }
                                            }}
                                          >
                                            <i className='fas fa-trash'></i>
                                          </button>
                                        </li>
                                        <li className='list-group-item'>
                                          <Link to='/renew'>
                                            <button
                                              className='btn btn-primary btn-sm rounded-0'
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                            >
                                              <i className='fas fa-undo'></i>
                                            </button>
                                          </Link>

                                          <Link to='/insurance/print'>
                                            <button
                                              onClick={() =>
                                                setCurrent(insurance)
                                              }
                                              type='submit'
                                              className='btn btn-secondary btn-sm rounded-0'
                                            >
                                              <i className='fas fa-print'></i>
                                            </button>
                                          </Link>
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                </Fragment>
                              ))
                            : search &&
                              search.map((insurance) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td>
                                        <i
                                          data-toggle='collapse'
                                          id={`row${insurance.id}`}
                                          data-target={`.row${insurance.id}`}
                                          class='clickable fas fa-plus sm icon'
                                        ></i>
                                        {new Date(
                                          insurance.createdAt
                                        ).toLocaleDateString('zh-Hans-CN')}
                                      </td>
                                      <td>{insurance.insured_name}</td>

                                      <td>{insurance.policy_number}</td>
                                      {/* <td>{insurance.inception_date}</td>
                          <td>{insurance.expiry_date}</td> */}
                                      <td>{insurance.mv_registration}</td>

                                      <td>
                                        <Link to='/view'>
                                          <button
                                            className='btn btn-info btn-sm rounded-0'
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                          >
                                            <i className='fa fa-eye '></i>
                                          </button>
                                        </Link>
                                      </td>

                                      <td>
                                        <Link to='/edit'>
                                          <button
                                            className='btn btn-warning btn-sm rounded-0'
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                          >
                                            <i className='fas fa-pencil-alt'></i>
                                          </button>
                                        </Link>
                                      </td>
                                      <td>
                                        <button
                                          className='btn btn-danger btn-sm rounded-0'
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                'Do you want to remove Insurance?'
                                              )
                                            ) {
                                              deleteInsurance(insurance.id);
                                            }
                                          }}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </button>
                                      </td>
                                      <td>
                                        <Link to='/renew'>
                                          <button
                                            className='btn btn-primary btn-sm rounded-0'
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                          >
                                            <i className='fas fa-undo'></i>
                                          </button>
                                        </Link>
                                      </td>

                                      <td>
                                        <Link to='/insurance/print'>
                                          <button
                                            onClick={() =>
                                              setCurrent(insurance)
                                            }
                                            type='submit'
                                            className='btn btn-secondary btn-sm rounded-0'
                                          >
                                            <i className='fas fa-print'></i>
                                          </button>
                                        </Link>
                                      </td>
                                    </tr>
                                    <tr class={`collapse row${insurance.id}`}>
                                      <td>
                                        <ul class='list-group list-group-flush'>
                                          <li class='list-group-item'>
                                            Policy No: {insurance.policy_number}
                                          </li>
                                          <li class='list-group-item'>
                                            Inception Date:{' '}
                                            {new Date(
                                              insurance.inception_date
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Expiry Date:{' '}
                                            {new Date(
                                              insurance.expiry_date
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Mv Registration:{' '}
                                            {insurance.mv_registration}
                                          </li>
                                          <li class='list-group-item'>
                                            Tonnage: {insurance.tonnage}
                                          </li>
                                          <li class='list-group-item'>
                                            Insurer: {insurance.insurer}
                                          </li>
                                          <li class='list-group-item'>
                                            Date of Issue:{' '}
                                            {new Date(
                                              insurance.createdAt
                                            ).toLocaleDateString('zh-Hans-CN')}
                                          </li>
                                          <li class='list-group-item'>
                                            Amount: {insurance.amount}
                                          </li>
                                          <li class='list-group-item'>
                                            Contact: {insurance.phone}
                                          </li>

                                          <li className='list-group-item'>
                                            Action:
                                            <Link to='/view'>
                                              <button
                                                className='btn btn-info btn-sm rounded-0'
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                              >
                                                <i className='fa fa-eye '></i>
                                              </button>
                                            </Link>
                                            <Link to='/edit'>
                                              <button
                                                className='btn btn-warning btn-sm rounded-0'
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                              >
                                                <i className='fas fa-pencil-alt'></i>
                                              </button>
                                            </Link>
                                            <button
                                              className='btn btn-danger btn-sm rounded-0'
                                              onClick={() => {
                                                if (
                                                  window.confirm(
                                                    'Do you want to remove Insurance?'
                                                  )
                                                ) {
                                                  deleteInsurance(insurance.id);
                                                }
                                              }}
                                            >
                                              <i className='fas fa-trash'></i>
                                            </button>
                                          </li>
                                          <li className='list-group-item'>
                                            <Link to='/renew'>
                                              <button
                                                className='btn btn-primary btn-sm rounded-0'
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                              >
                                                <i className='fas fa-undo'></i>
                                              </button>
                                            </Link>

                                            <Link to='/insurance/print'>
                                              <button
                                                onClick={() =>
                                                  setCurrent(insurance)
                                                }
                                                type='submit'
                                                className='btn btn-secondary btn-sm rounded-0'
                                              >
                                                <i className='fas fa-print'></i>
                                              </button>
                                            </Link>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default ExitedInsurances;
