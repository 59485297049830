import React, { Fragment, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';
import InsuranceContext from '../../context/insurance/insuranceContext';

import Alert from '../layout/Alert';

import { Button, Modal } from 'react-bootstrap';

toast.configure();
const SmsModule = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const insuranceContext = useContext(InsuranceContext);
  const { isAuthenticated, loaduser, setCurrent, current, user, logout } =
    authContext;
  const {
    setAlert,
    sendIndividualSms,
    sendActiveInsuranceSms,
    sendCustomExpiredSms,
    sendExpiringDaySms,
    sendExpiringWeekSms,
    sendExpiringMonthSms,
  } = alertContext;
  const {
    getAllInsurance,

    insurance,

    search,
    searchInsurance,

    PAGE_NUMBER,
  } = insuranceContext;

  const [page, setPage] = useState(PAGE_NUMBER);

  //increment page
  const scrollToEnd = () => {
    setPage(page + 1);
  };

  // const unique = insurance.filter((item, index) => insurance.indexOf(item) === index);

  //when scrolled
  window.onscroll = function () {
    //check if paged is scrolled
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  //end of pagination

  const [modalState, setModalState] = useState(false);

  const handleShowModalOne = () => {
    setModalState('modal-one');
  };

  const handleShowModalTwo = () => {
    setModalState('modal-two');
  };

  const handleShowModalThree = () => {
    setModalState('modal-three');
  };

  const handleShowModalFour = () => {
    setModalState('modal-four');
  };

  const handleShowModalFive = () => {
    setModalState('modal-five');
  };

  const handleShowModalSix = () => {
    setModalState('modal-six');
  };
  const handleShowModalSeven = () => {
    setModalState('modal-seven');
  };

  const handleClose = () => {
    setModalState('close');
  };

  const [admin, setAdmin] = useState({
    phone: '',
    message: '',
    searchField: '',
  });
  const { phone, message, searchField } = admin;
  const onChange = (e) =>
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });

  const processIndividualSms = () => {
    //e.preventDefault();

    sendIndividualSms({
      phone,
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      phone: '',
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  const processIndividualAllSms = () => {
    //e.preventDefault();

    sendIndividualSms({
      phone: current,
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      phone: '',
      message: '',
    });

    window.location.href = '/sms';

    //props.history.push('/RegisteredUsers');
  };

  const processActiveSms = () => {
    //e.preventDefault();

    sendActiveInsuranceSms({
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  const processExpiredSms = () => {
    //e.preventDefault();

    sendCustomExpiredSms({
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  const processExpiringMonthSms = () => {
    //e.preventDefault();

    sendExpiringMonthSms({
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  const processExpiringWeekSms = () => {
    //e.preventDefault();

    sendExpiringWeekSms({
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  const processExpiringDaySms = () => {
    //e.preventDefault();

    sendExpiringDaySms({
      message,
    });

    toast.success('Message sent...', {
      position: toast.POSITION.TOP_CENTER,
    });

    setTimeout(handleClose, 2000);

    setAdmin({
      message: '',
    });

    //props.history.push('/RegisteredUsers');
  };

  useEffect(() => {
    if (isAuthenticated) {
      loaduser();
      //  getActiveInsurance(page);
      props.history.push('/sms');
    }
    getAllInsurance(page);

    //searchInsurance(searchField);

    loaduser();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history, page]);

  const onLogout = () => {
    logout();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    searchInsurance(searchField);
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form
                onSubmit={onSubmit}
                class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'
              >
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                    name='searchField'
                    value={searchField}
                    onChange={onChange}
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='submit'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <li class='nav-item dropdown no-arrow d-sm-none'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='searchDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i class='fas fa-search fa-fw'></i>
                  </a>

                  <div
                    class='dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in'
                    aria-labelledby='searchDropdown'
                  >
                    <form
                      class='form-inline mr-auto w-100 navbar-search'
                      onSubmit={onSubmit}
                    >
                      <div class='input-group'>
                        <input
                          type='text'
                          class='form-control bg-light border-0 small'
                          placeholder='Search for...'
                          aria-label='Search'
                          aria-describedby='basic-addon2'
                          name='searchField'
                          value={searchField}
                          onChange={onChange}
                        />
                        <div class='input-group-append'>
                          <button class='btn btn-primary' type='submit'>
                            <i class='fas fa-search fa-sm'></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
                <Alert />
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>

            {user && user.role == 'admin' ? (
              <div class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-12'></div>
                </div>
                <div class='card shadow mb-4'>
                  <div class='card-header py-3'>
                    <h4>
                      <b>Sms Notification Templates</b>
                    </h4>
                  </div>
                  <div className='card-body'>
                    <div className='form-row'>
                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0 '
                          onClick={handleShowModalOne}
                        >
                          Custom Individual Sms
                        </button>
                      </div>

                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0'
                          onClick={() => {
                            {
                              sendActiveInsuranceSms();
                              toast.success('Message sent...', {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            }
                          }}
                        >
                          All Active Insurances
                        </button>
                        {/* <button
                          className='btn btn-primary btn-sm rounded-0 '
                          // onClick={handleShowModalTwo}
                          sendActiveInsuranceSms
                        >
                          All Active Insurances
                        </button> */}
                      </div>

                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0'
                          onClick={() => {
                            {
                              sendCustomExpiredSms();
                              toast.success('Message sent...', {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            }
                          }}
                        >
                          All Expired Insurances
                        </button>
                        {/* <button
                          className='btn btn-primary btn-sm rounded-0 '
                          onClick={handleShowModalThree}
                        >
                          All Expired Insurances
                        </button> */}
                      </div>
                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0'
                          onClick={function () {
                            sendExpiringMonthSms();
                            toast.success('Message sent...', {
                              position: toast.POSITION.TOP_CENTER,
                            });
                          }}
                        >
                          Expiring in 30 Days
                        </button>
                        {/* <button
                          className='btn btn-primary btn-sm rounded-0 '
                          onClick={handleShowModalFour}
                        >
                          Expiring In 30 Days
                        </button> */}
                      </div>
                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0'
                          onClick={() => {
                            {
                              sendExpiringWeekSms();
                              toast.success('Message sent...', {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            }
                          }}
                        >
                          Expiring in 7 Days
                        </button>
                        {/* <button
                          className='btn btn-primary btn-sm rounded-0 '
                          onClick={handleShowModalFive}
                        >
                          Expiring In 7 Days
                        </button> */}
                      </div>
                      <div className='form-group col-md-2'>
                        <button
                          className='btn btn-primary btn-sm rounded-0'
                          onClick={() => {
                            {
                              sendExpiringDaySms();
                              toast.success('Message sent...', {
                                position: toast.POSITION.TOP_CENTER,
                              });
                            }
                          }}
                        >
                          Expiring in 1 Day
                        </button>
                        {/* <button
                          className='btn btn-primary btn-sm rounded-0 '
                          onClick={handleShowModalSix}
                        >
                          Expiring In 1 Day
                        </button> */}
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='table-responsive'>
                        <table
                          class='table table-bordered'
                          id='dataTable'
                          width='100%'
                          cellspacing='0'
                        >
                          <thead>
                            <tr>
                              <th scope='col'>Name</th>
                              <th scope='col'>Policy Number</th>

                              <th scope='col'>Notify</th>
                            </tr>
                          </thead>
                          <tbody>
                            {insurance && searchField === ''
                              ? insurance.map((insurance) => (
                                  <Fragment>
                                    <tr>
                                      <td>{insurance.insured_name}</td>

                                      <td>{insurance.policy_number}</td>

                                      <td>
                                        <button
                                          className='btn btn-danger btn-sm rounded-0'
                                          onClick={() => {
                                            handleShowModalSeven();
                                            setCurrent(insurance.phone);
                                          }}
                                        >
                                          <i className='fas fa-bell'></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </Fragment>
                                ))
                              : search &&
                                search.map((insurance) => {
                                  return (
                                    <Fragment>
                                      <tr>
                                        <td>{insurance.insured_name}</td>

                                        <td>{insurance.policy_number}</td>

                                        <td>
                                          <button
                                            className='btn btn-danger btn-sm rounded-0'
                                            onClick={() => {
                                              handleShowModalSeven();
                                              setCurrent(insurance.phone);
                                            }}
                                          >
                                            <i className='fas fa-bell'></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <h1>403 | THIS ACTION IS UNAUTHORIZED</h1>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        <Modal show={modalState === 'modal-one'}>
          <Modal.Header>
            <Modal.Title>Custom Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />
                <div className='form-group '>
                  <label for='inputEmail4'>Phone Number</label>
                  <input
                    type='name'
                    className='form-control'
                    id='inputEmail4'
                    placeholder='0707711118...'
                    name='phone'
                    value={phone}
                    onChange={onChange}
                  />
                </div>

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processIndividualSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-two'}>
          <Modal.Header>
            <Modal.Title>Custom Message (Active Insurance)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processActiveSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-three'}>
          <Modal.Header>
            <Modal.Title>Custom Message(Expired Insurance)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processExpiredSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-four'}>
          <Modal.Header>
            <Modal.Title>Custom Message(Expiring in 30 Days)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processExpiringMonthSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-five'}>
          <Modal.Header>
            <Modal.Title>Custom Message(Expiring in 7 days)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processExpiringWeekSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-six'}>
          <Modal.Header>
            <Modal.Title>Custom Message(Expiring in 1 Day)</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processExpiringDaySms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div>
        <Modal show={modalState === 'modal-seven'}>
          <Modal.Header>
            <Modal.Title>Custom Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className='card-body'>
                <Alert />

                <div className='form-group '>
                  <label for='inputPassword4'></label>
                  <textarea
                    class='form-control'
                    placeholder='Compose Message Here '
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2 '
                    name='message'
                    value={message}
                    onChange={onChange}
                  ></textarea>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={processIndividualAllSms}>SEND SMS</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </body>
  );
};

export default SmsModule;
