import {
  LOGBOOK_ERROR,
  ADD_LOGBOOK_SUCCESS,
  LOGBOOK_LOADED,
  UPDATE_LOGBOOK,
  DELETE_LOGBOOK,
  CLEAR_ERRORS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case LOGBOOK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case LOGBOOK_LOADED:
      return {
        ...state,

        loading: false,
        logbook: action.payload,
      };
    case ADD_LOGBOOK_SUCCESS:
      return {
        ...state,
        logbook: [...state.logbook, action.payload],
        error: 'none',
        loading: false,
      };

    case UPDATE_LOGBOOK:
      return {
        ...state,
        logbook: state.logbook.map((logbook) =>
          logbook.id === action.payload.id ? action.payload : logbook
        ),
        error: 'none',
        loading: false,
      };

    case DELETE_LOGBOOK:
      return {
        ...state,
        logbook: state.logbook.filter(
          (logbook) => logbook.id !== action.payload
        ),
        loading: false,
      };

    default:
      return state;
  }
};
