import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import AuthContext from '../../context/auth/authContext';
import InsuranceContext from '../../context/insurance/insuranceContext';
import { useReactToPrint } from 'react-to-print';
import { CSVLink } from 'react-csv';
const WeeklyTransaction = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const authContext = useContext(AuthContext);
  const insuranceContext = useContext(InsuranceContext);

  const {
    isAuthenticated,
    logout,

    user,

    current,

    loaduser,
  } = authContext;
  const { getWeeklyTransaction, weeklyTransaction } = insuranceContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/transactionStaff/weekly');
    }
    loaduser();
    getWeeklyTransaction(current);

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);
  const onLogout = () => {
    logout();
  };

  //csv
  const headers = [
    { label: 'Created', key: 'createdAt' },
    { label: 'Expiry Date', key: 'expiry_date' },
    { label: 'Insured', key: 'insured_name' },
    { label: 'Policy Number', key: 'policy_number' },
    { label: 'Inception', key: 'inception_date' },
    { label: 'Mv Registration', key: 'mv_registration' },
    { label: 'Tonnage', key: 'tonnage' },
    { label: 'Date of issue', key: 'issue_date' },
    { label: 'Contact', key: 'phone' },
  ];

  const csvReport = {
    filename: 'Mahir Insurance Agency LTD.CSV',
    headers: headers,
    data: weeklyTransaction,
  };
  //PRINT

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div style={{ fontSize: '30px' }}>
          <center id='top'>
            <div class='info'>
              <h1>
                <b>Mahir Insurance Agency LTD</b>{' '}
              </h1>
            </div>
          </center>
          &nbsp;
          <div>
            <center>
              <header>
                <h4>
                  <b>WEEKLY TRANSACTION FOR {user.user_name}</b>
                </h4>
              </header>
            </center>
          </div>
          &nbsp;
          <div id='bot'>
            <div id='table'>
              <table className='table'>
                <thead>
                  <th scope='col'>
                    <b>CREATED</b>
                  </th>
                  <th scope='col'>
                    <b>INSURED</b>
                  </th>
                  <th scope='col'>
                    <b>POLICY NUMBER</b>
                  </th>

                  <th scope='col'>
                    <b>INCEPTION DATE</b>
                  </th>
                  <th scope='col'>
                    <b>EXPIRY DATE</b>
                  </th>
                  <th scope='col'>
                    <b>MV REGISTRATION</b>
                  </th>
                  <th scope='col'>
                    <b>TONNAGE</b>
                  </th>
                  <th scope='col'>
                    <b>INSURER</b>
                  </th>

                  <th scope='col'>
                    <b>AMOUNT</b>
                  </th>
                  <th scope='col'>
                    <b>PHONE</b>
                  </th>
                </thead>
                <tbody>
                  {weeklyTransaction &&
                    weeklyTransaction.map((weeklyTransaction) => {
                      return (
                        <Fragment>
                          <tr>
                            <td>
                              {new Date(
                                weeklyTransaction.createdAt
                              ).toLocaleDateString('zh-Hans-CN')}
                            </td>
                            <td>{weeklyTransaction.insured_name}</td>

                            <td>{weeklyTransaction.policy_number}</td>
                            <td>{weeklyTransaction.inception_date}</td>
                            <td>{weeklyTransaction.expiry_date}</td>
                            <td>{weeklyTransaction.mv_registration}</td>
                            <td>{weeklyTransaction.tonnage}</td>
                            <td>{weeklyTransaction.insurer}</td>

                            <td>{weeklyTransaction.amount}</td>
                            <td>{weeklyTransaction.phone}</td>
                          </tr>
                        </Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }

  //END OF PRINT
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {user && user.role == 'admin' ? (
              <div class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-12'>
                    <h3>
                      <b>Transactions Report For Staff</b>
                      <div style={{ display: 'none' }}>
                        <ComponentToPrint ref={componentRef} />
                      </div>
                      <button
                        onClick={handlePrint}
                        type='submit'
                        className='btn btn-warning btn-sm rounded-0 float-right'
                      >
                        <i className='fas fa-print  '>print</i>
                      </button>
                      <div class='float-right'>&nbsp;</div>

                      <div class='float-right'>&nbsp;</div>

                      <CSVLink
                        {...csvReport}
                        className='btn btn-success btn-sm rounded-0 float-right'
                      >
                        <i className='fas fa-download  '>csv</i>
                      </CSVLink>
                    </h3>
                  </div>
                </div>
                <div class='card shadow mb-4'>
                  <div className='card-body'>
                    <form>
                      <div class='form-row'>
                        <div className='form-group col-md-3'></div>

                        <div className='row'>
                          <div className='col'>
                            <Link
                              to='/transactionStaff/daily'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Daily Transactions
                              </button>
                            </Link>
                          </div>
                          <div className='col'>
                            <Link
                              to='/transactionStaff/weekly'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Weekly Transactions
                              </button>
                            </Link>
                          </div>

                          <div className='col'>
                            <Link
                              to='/transactionStaff/monthly'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Monthly Transactions
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div> &nbsp; &nbsp; &nbsp; &nbsp;</div>
                    <div className='table-responsive'>
                      <table
                        class='table table-bordered'
                        id='dataTable'
                        width='100%'
                        cellspacing='0'
                      >
                        <thead>
                          <tr>
                            <th scope='col'>Created</th>
                            <th scope='col'>Insured</th>
                            <th scope='col'>Policy Number</th>

                            <th scope='col'>Inception Date</th>
                            <th scope='col'>Expiry Date</th>
                            <th scope='col'>Mv Registration</th>
                            <th scope='col'>Tonnage</th>
                            <th scope='col'>Insurer</th>

                            <th scope='col'>Amount</th>
                            <th scope='col'>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {weeklyTransaction &&
                            weeklyTransaction.map((weeklyTransaction) => {
                              return (
                                <Fragment>
                                  <tr>
                                    <td>
                                      {new Date(
                                        weeklyTransaction.createdAt
                                      ).toLocaleDateString('zh-Hans-CN')}
                                    </td>
                                    <td>{weeklyTransaction.insured_name}</td>

                                    <td>{weeklyTransaction.policy_number}</td>
                                    <td>{weeklyTransaction.inception_date}</td>
                                    <td>{weeklyTransaction.expiry_date}</td>
                                    <td>{weeklyTransaction.mv_registration}</td>
                                    <td>{weeklyTransaction.tonnage}</td>
                                    <td>{weeklyTransaction.insurer}</td>
                                    <td>{weeklyTransaction.amount}</td>
                                    <td>{weeklyTransaction.status}</td>
                                  </tr>
                                </Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <h1>403 | THIS ACTION IS UNAUTHORIZED</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default WeeklyTransaction;
