import React, { useRef, useContext } from 'react';

import IdleTimer from 'react-idle-timer';

const Timmer = (props) => {
  const idleTimerRef = useRef(null);
  const onIdle = () => {
    localStorage.removeItem('token');
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={600 * 1000}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
};

export default Timmer;
