export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';

export const USER_LOADED = 'USER_LOADED';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const UPDATE_ERROR = 'UPDATE_ERROR';

export const UPDATE_USER = 'UPDATE_USER';

export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const USERS_LOADED = 'USERS_LOADED';

export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';

export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const ADD_LOGBOOK_SUCCESS = 'ADD_LOGBOOK_SUCCESS';
export const UPDATE_LOGBOOK = 'UPDATE_LOGBOOK';
export const DELETE_LOGBOOK = 'DELETE_LOGBOOK';

export const LOGBOOK_LOADED = 'LOGBOOK_LOADED';

export const LOGBOOK_ERROR = 'LOGBOOK_ERROR';

export const ALL_INSURANCE_LOADED = 'ALL_INSURANCE_LOADED';
export const EXITED_INSURANCE_LOADED = 'EXITED_INSURANCE_LOADED';
export const ACTIVE_INSURANCE_LOADED = 'ACTIVE_INSURANCE_LOADED';
export const EXPIRING_INSURANCE_LOADED = 'EXPIRING_INSURANCE_LOADED';
export const EXPIRING_INSURANCE_WEEK = 'EXPIRING_INSURANCE_WEEK';
export const EXPIRING_INSURANCE_MONTH = 'EXPIRING_INSURANCE_MONTH';
export const EXPIRED_INSURANCE_LOADED = 'EXPIRED_INSURANCE_LOADED';
export const INSURANCE_ERROR = 'INSURANCE_ERROR';
export const ADD_INSURANCE = 'ADD_INSURANCE';
export const UPDATE_INSURANCE = 'UPDATE_INSURANCE';
export const DELETE_INSURANCE = 'DELETE_INSURANCE';
export const EXIT_INSURANCE = 'EXIT_INSURANCE';
export const EXPIRY_REPORT = 'EXPIRY_REPORT';
export const SALES_REPORT = 'SALES_REPORT';
export const INSURANCE_SEARCH = 'INSURANCE_SEARCH';

export const INDIVIDUAL_SMS = 'INDIVIDUAL_SMS';

export const DAILY_TRANSACTION_STAFF = 'DAILY_TRANSACTION_STAFF';
export const WEEKLY_TRANSACTION_STAFF = 'WEEKLY_TRANSACTION_STAFF';
export const MONTHLY_TRANSACTION_STAFF = 'MONTHLY_TRANSACTION_STAFF';

export const DAILY_TRANSACTION = 'DAILY_TRANSACTION';
export const WEEKLY_TRANSACTION = 'WEEKLY_TRANSACTION';
export const MONTHLY_TRANSACTION = 'MONTHLY_TRANSACTION';

export const SET_PRICE = 'SET_PRICE';
export const SET_PRICE_TYPE = 'SET_PRICE_TYPE';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const RENEW_INSURANCE = 'RENEW INSURANCE';
