import React, { Fragment, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Sidebar from '../layout/Sidebar';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import InsuranceContext from '../../context/insurance/insuranceContext';
toast.configure();
const RenewInsurance = (props) => {
  const authContext = useContext(AuthContext);
  const insuranceContext = useContext(InsuranceContext);

  const {
    isAuthenticated,
    logout,

    user,

    current,

    loaduser,
  } = authContext;
  const { renewInsurance, error, clearErrors } = insuranceContext;
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/renew');
    }
    loaduser();

    if (error === 'Insurance already exists') {
      toast.error('Failed to renew insurance', {
        position: toast.POSITION.TOP_CENTER,
      });
      //  setAlert(`Insurance already exists`, 'danger');
      // setRegister({
      //   ...register,
      //   error: 'none',
      //   //[e.target.SeatNumber]: e.target.value.split(","),
      // });
      clearErrors();
    } else if (error === 'none') {
      toast.success('Successfuly renewed insurance', {
        position: toast.POSITION.TOP_CENTER,
      });
      clearErrors();

      setTimeout(props.history.push('/insurances'), 4000);

      //props.history.push('/insurances');
    }

    // clearErrors();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history, error]);
  const [inception_date, setInceptionDate] = useState(
    new Date(current.inception_date)
  );
  const [disabled, setDisabled] = useState(false);
  const [expiry_date, setExpiryDate] = useState(new Date(current.expiry_date));
  const [register, setRegister] = useState({
    insured_name: current.insured_name,
    policy_number: current.policy_number,
    // inception_date: current.inception_date,
    // expiry_date: current.expiry_date,
    mv_registration: current.mv_registration,
    tonnage: current.tonnage,
    insurer: current.insurer,
    issue_date: current.issue_date,
    amount: current.amount,
    chasis_number: current.chasis_number,
    phone: current.phone,
  });
  let {
    insured_name,
    policy_number,
    // inception_date,
    // expiry_date,
    mv_registration,
    tonnage,
    insurer,
    issue_date,
    amount,
    chasis_number,
    phone,
  } = register;
  const onChange = (e) =>
    setRegister({
      ...register,
      [e.target.name]: e.target.value,
      //[e.target.SeatNumber]: e.target.value.split(","),
    });
  const onLogout = () => {
    logout();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    renewInsurance(
      {
        insured_name,
        policy_number,
        inception_date,
        expiry_date,
        mv_registration,
        tonnage,
        insurer,
        issue_date,
        amount,
        chasis_number,
        phone,
      },
      current
    );
    setDisabled(true);

    // window.location.href = '/insurances';
    // props.history.push('/insurances');
  };

  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-lg-12'></div>
              </div>
              <div class='card shadow mb-4'>
                <div class='card-header py-3'>
                  Renew Insurance
                  <button className=' btn btn-primary float-right '>
                    <Link to='/Insurances' className=' nav-link text-white'>
                      Check All Insurances
                    </Link>
                  </button>
                </div>
                <div className='card-body'>
                  <form onSubmit={onSubmit}>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <label for='inputEmail4'>Insured Name</label>
                        <input
                          type='name'
                          className='form-control'
                          id='inputEmail4'
                          name='insured_name'
                          value={insured_name}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Policy Number</label>
                        <input
                          type=''
                          className='form-control'
                          id='inputPassword4'
                          name='policy_number'
                          value={policy_number}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Inception Date</label>
                        <DatePicker
                          className='form-control'
                          dateFormat='yyyy/MM/dd'
                          selected={inception_date}
                          onChange={(date) => setInceptionDate(date)}
                        />
                        {/* <input
                          type='date'
                          className='form-control'
                          id='inputPassword4'
                          name='inception_date'
                          value={inception_date}
                          onChange={onChange}
                          required
                        /> */}
                      </div>
                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Expiry Date</label>
                        <DatePicker
                          className='form-control'
                          dateFormat='yyyy/MM/dd'
                          selected={expiry_date}
                          onChange={(date) => setExpiryDate(date)}
                        />
                        {/* <input
                          type='date'
                          className='form-control'
                          id='inputPassword4'
                          name='expiry_date'
                          value={expiry_date}
                          onChange={onChange}
                          required
                        /> */}
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>MV Registration</label>
                        <input
                          type=''
                          className='form-control'
                          id='inputPassword4'
                          name='mv_registration'
                          value={mv_registration}
                          onChange={onChange}
                          required
                        />
                      </div>
                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Tonnage</label>
                        <input
                          type=''
                          className='form-control'
                          id='inputPassword4'
                          name='tonnage'
                          value={tonnage}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Insurer</label>
                        <input
                          type='name'
                          className='form-control'
                          id='inputPassword4'
                          name='insurer'
                          value={insurer}
                          onChange={onChange}
                          required
                        />
                      </div>

                      {/* <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Date of Issue</label>
                        <input
                          type='date'
                          className='form-control'
                          id='inputPassword4'
                          name='issue_date'
                          value={issue_date}
                          onChange={onChange}
                          required
                        />
                      </div> */}

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Amount</label>
                        <input
                          type='number'
                          className='form-control'
                          id='inputPassword4'
                          name='amount'
                          value={amount}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Chasis Number</label>
                        <input
                          type=''
                          className='form-control'
                          id='inputPassword4'
                          name='chasis_number'
                          value={chasis_number}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Phone</label>
                        <input
                          type='number'
                          className='form-control'
                          id='inputPassword4'
                          name='phone'
                          value={phone}
                          onChange={onChange}
                          required
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <button
                          className='btn btn-primary btn-block btn-lg'
                          type='submit'
                          onClick={(e) => {
                            onSubmit(e);
                          }}
                        >
                          Renew Insurance
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default RenewInsurance;
