import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../layout/Sidebar';

const ExpiringInsurances = (props) => {
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'></span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a className='dropdown-item' href='#!'>
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-lg-12'></div>
              </div>
              <div class='card shadow mb-4'>
                <div class='card-header py-3'>
                  <div className='form-group col-md-3'>
                    <label for='inputPassword4'>
                      <b>EXPIRING INSURANCES</b>
                    </label>
                  </div>

                  <div className='row'>
                    <div className='col'>
                      <Link
                        to='/expiring/day'
                        style={{ textDecoration: 'none' }}
                      >
                        <button className='btn btn-primary btn-block btn-sm'>
                          Less Than 1 Day to Expiry
                        </button>
                      </Link>
                    </div>
                    <div className='col'>
                      <Link
                        to='/expiring/week'
                        style={{ textDecoration: 'none' }}
                      >
                        <button className='btn btn-primary btn-block btn-sm'>
                          Less Than 7 Days to Expiry
                        </button>
                      </Link>
                    </div>

                    <div className='col'>
                      <Link
                        to='/expiring/month'
                        style={{ textDecoration: 'none' }}
                      >
                        <button className='btn btn-primary btn-block btn-sm'>
                          Less Than 30 Days to Expiry
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <table className='table'>
                    <thead>
                      <tr>
                        <th scope='col'>Created </th>
                        <th scope='col'>Insured</th>
                        <th scope='col'>Policy Number</th>
                        <th scope='col'>Inception Date</th>

                        <th scope='col'>Mv Registration</th>
                        <th scope='col'>View </th>
                        <th scope='col'>Edit</th>
                        <th scope='col'>Delete</th>
                        <th scope='col'>Renew</th>
                        <th scope='col'>Notify</th>
                        <th scope='col'>Print</th>
                        <th scope='col'>Exit</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default ExpiringInsurances;
