import React, { useReducer } from 'react';
import axios from 'axios';
import AlertContext from './alertContext';
import AlertReducer from './alertReducer';
import {
  SET_ALERT,
  REMOVE_ALERT,
  INDIVIDUAL_SMS,
  INSURANCE_ERROR,
} from '../types';
//CREATE OUR INITIAL STATE
const AlertState = (props) => {
  const initialState = {
    alert: [],
  };
  //dispatch to our reducer the actions
  const [state, dispatch] = useReducer(AlertReducer, initialState);

  //set Alert
  const setAlert = (msg, type) => {
    dispatch({
      type: SET_ALERT,
      payload: { msg, type },
    });
    //remove alert after 5 sec
    setTimeout(() => {
      dispatch({
        type: REMOVE_ALERT,
      });
      //this.setState({ alert: null });
    }, 5000);
  };

  //register user
  const sendIndividualSms = async (formData) => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/individual-sms',
        formData,
        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendExpiredSms = async (id) => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        `https://api.mahir.co.ke/insurance/expired-sms/${id}`,

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        // payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        //  payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendApproachingExpirySms = async (id) => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        `https://api.mahir.co.ke/insurance/expiring-sms/${id}`,

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendActiveInsuranceSms = async () => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/all/active-sms',

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendCustomExpiredSms = async () => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/all/expired-sms',

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendExpiringMonthSms = async () => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/all/expiringMonth-sms',

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendExpiringWeekSms = async () => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/all/expiringWeek-sms',

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const sendExpiringDaySms = async () => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/all/expiringDay-sms',

        config
      );
      dispatch({
        type: INDIVIDUAL_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //return the provider that wraps the entire app
  return (
    <AlertContext.Provider
      value={{
        alert: state.alert,
        //wehere the action goes
        setAlert,
        sendIndividualSms,
        sendExpiredSms,
        sendApproachingExpirySms,
        sendActiveInsuranceSms,
        sendCustomExpiredSms,
        sendExpiringDaySms,
        sendExpiringWeekSms,
        sendExpiringMonthSms,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
export default AlertState;
