import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';

import AuthContext from '../../context/auth/authContext';

const ViewInsurance = (props) => {
  const authContext = useContext(AuthContext);

  const {
    isAuthenticated,
    logout,

    user,
    current,

    loaduser,
  } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/view');
    }
    loaduser();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);
  const onLogout = () => {
    logout();
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>
                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'></div>
              <div class='card shadow mb-4'>
                <div class='card-header py-3'></div>

                <div className='card-body'>
                  <table class='table table-bordered'>
                    <thead>
                      <tr>
                        <th scope='col'>
                          {' '}
                          Insurance Details
                          <button className='btn btn-primary float-right'>
                            <Link
                              to='/insurances'
                              className=' fas fa-undo nav-link text-white'
                            >
                              Go back to list
                            </Link>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tonnage : {current.tonnage} </td>
                      </tr>
                      <tr>
                        <td>Insurer : {current.insurer} </td>
                      </tr>

                      <tr>
                        <td>
                          Date of Issue:{' '}
                          {new Date(current.createdAt).toLocaleDateString(
                            'zh-Hans-CN'
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Expiry Date:{' '}
                          {new Date(current.expiry_date).toLocaleDateString(
                            'zh-Hans-CN'
                          )}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Inception Date:{' '}
                          {new Date(current.inception_date).toLocaleDateString(
                            'zh-Hans-CN'
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Chasis No: {current.chasis_number} </td>
                      </tr>

                      <tr>
                        <td>Amount: {current.amount}</td>
                      </tr>
                      <tr>
                        <td>Contact: {current.phone}</td>
                      </tr>
                      <tr>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default ViewInsurance;
