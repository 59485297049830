import React, { useContext, useEffect, useState } from 'react';

import AuthContext from '../../context/auth/authContext';
import Sidebar from '../layout/Sidebar';
import { Link } from 'react-router-dom';
const UpdateUser = (props) => {
  const authContext = useContext(AuthContext);
  const {
    isAuthenticated,
    logout,
    current,
    user,
    updateUser,
    loaduser,
    loadUsers,
  } = authContext;
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/updateUser/edit');
    }
    loaduser();
    loadUsers();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);
  const [update, setUpdate] = useState({
    user_name: current.user_name,
    email: current.email,
    role: current.role,
    phone: current.phone,
  });
  let { user_name, email, role, phone } = update;
  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  const onLogout = () => {
    logout();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    updateUser(
      {
        user_name,
        email,
        role,
        phone,
      },
      current
    );
    //loadUsers();
    props.history.push('/RegisteredUsers');
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}
            <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div className='input-group-append'>
                    <button className='btn btn-primary' type='button'>
                      <i className='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div className='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#/'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-lg-12'></div>
              </div>

              <div class='card shadow mb-4'>
                <div class='card-header py-3'>
                  Update User
                  <button className=' btn btn-primary float-right '>
                    <Link
                      to='/RegisteredUsers'
                      className=' nav-link text-white'
                    >
                      Check Registered Users
                    </Link>
                  </button>
                </div>
                <div className='card-body'>
                  <form onSubmit={onSubmit}>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <label for='inputEmail4'>User Name</label>
                        <input
                          type='text'
                          className='form-control'
                          id='inputEmail4'
                          name='user_name'
                          value={user_name}
                          onChange={onChange}
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>email</label>
                        <input
                          type='email'
                          className='form-control'
                          id='inputPassword4'
                          name='email'
                          value={email}
                          onChange={onChange}
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Role</label>
                        <select
                          className='form-control form-control-sm'
                          value={role}
                          name='role'
                          onChange={onChange}
                        >
                          <option></option>
                          <option>admin</option>
                          <option>user</option>
                        </select>
                      </div>

                      <div className='form-group col-md-12'>
                        <label for='inputPassword4'>Phone</label>
                        <input
                          type='number'
                          className='form-control'
                          id='inputPassword4'
                          value={phone}
                          name='phone'
                          onChange={onChange}
                        />
                      </div>

                      <div className='form-group col-md-12'>
                        <button
                          className='btn btn btn-primary btn-block btn-lg'
                          type='submit'
                        >
                          Update User
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default UpdateUser;
