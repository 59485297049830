import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';

import Sidebar from '../layout/Sidebar';

const LoginReport = (props) => {
  const authContext = useContext(AuthContext);

  const {
    isAuthenticated,
    logout,
    users,
    user,
    setCurrent,
    current,
    deleteUser,
    loaduser,
    loadUsers,
  } = authContext;
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/staff');
    }
    loaduser();
    loadUsers();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);
  const onLogout = () => {
    logout();
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>

            {user && user.role == 'admin' ? (
              <div class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-12'></div>
                </div>
                <div class='card shadow mb-4'>
                  <div class='card-header py-3'>
                    <h3>
                      <b>Login Time Report</b>
                    </h3>
                  </div>
                  <div className='card-body'>
                    <div className='table-responsive'>
                      <table
                        class='table table-bordered'
                        id='dataTable'
                        width='100%'
                        cellspacing='0'
                      >
                        <thead>
                          <tr>
                            <th scope='col'>User name</th>
                            <th scope='col'>role</th>
                            <th scope='col'>email</th>

                            <th scope='col'>Phone</th>

                            <th scope='col'>Time Login</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((usr) => {
                            return (
                              <Fragment>
                                <tr>
                                  <td>
                                    {usr.user_name}{' '}
                                    <i
                                      data-toggle='collapse'
                                      id={`row${usr.id}`}
                                      data-target={`.row${usr.id}`}
                                      class='clickable fas fa-plus sm icon'
                                    ></i>
                                  </td>
                                  <td>{usr.role}</td>
                                  <td>{usr.email}</td>

                                  <td>{usr.phone}</td>

                                  <td>{usr.last_activity}</td>
                                </tr>

                                <tr class={`collapse row${usr.id}`}>
                                  <td>
                                    <ul class='list-group list-group-flush'>
                                      <li className='list-group-item'>
                                        Logged In :{usr.last_activity}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <h1>403 | THIS ACTION IS UNAUTHORIZED</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default LoginReport;
