import React, { useState, useEffect, useContext } from 'react';

import AuthContext from '../../context/auth/authContext';
import AlertContext from '../../context/alert/alertContext';

import Alert from '../layout/Alert';
import Spiner from '../layout/Spinner/Spinner';

const Login = (props) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const {
    login,
    error,
    isAuthenticated,
    loaduser,
    loading,
    loadUsers,
    clearErrors,
  } = authContext;
  const { setAlert } = alertContext;

  useEffect(() => {
    if (isAuthenticated) {
      loaduser();
      loadUsers();
      props.history.push('/');
    }

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  const [admin, setAdmin] = useState({
    email: '',
    password: '',
  });
  const { email, password } = admin;
  const onChange = (e) =>
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();

    //check for validation
    if (error === 'invalid credentials') {
      setAlert(`${error}`, 'danger');
      clearErrors();
    } else {
      login({
        email,
        password,
      });

      //loading spinner
      if (loading) {
        return <Spiner />;
      }
    }
  };

  return (
    <div className='container mt-5 '>
      <div className='row h-100 justify-content-center align-items-center'>
        <div className='col-md-4'>
          <div className='card br-10 bg-dark'>
            <Alert />
            <div className='card-header'>
              <h3 className='text-center text-Blue'>SIGN IN</h3>
            </div>
            <div class='card-body'>
              <form onSubmit={onSubmit}>
                <input type='hidden' name='' />{' '}
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      <i className='fa fa-user'></i>
                    </span>
                  </div>
                  <input
                    type='email'
                    className='form-control form-control-lg'
                    name='email'
                    value={email}
                    placeholder='Email'
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>
                      <i className='fa fa-key'></i>
                    </span>
                  </div>
                  <input
                    type='password'
                    class='form-control form-control-lg'
                    name='password'
                    placeholder='Password'
                    value={password}
                    onChange={onChange}
                    required
                  />
                </div>
                <div className='row'>
                  <div className='col'>
                    <button
                      type='submit'
                      className='btn btn-block btn-lg btn-primary'
                    >
                      SIGN IN
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
