import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useReactToPrint } from 'react-to-print';

import AuthContext from '../../context/auth/authContext';

const AllInsurances = (props) => {
  const authContext = useContext(AuthContext);

  const { current } = authContext;

  useEffect(() => {
    setTimeout(handlePrint, 1000);

    // eslint-disable-next-line
  }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div id='invoice-POS' style={{ fontSize: '30px' }}>
          <div class='container'>
            <div
              className='column'
              style={{
                display: 'inline',
                width: '33.3%',
                float: 'right',
                margin: '0 auto',
                padding: '40px',
              }}
            >
              <div>
                <p>
                  <b>{current && current.insured_name}</b>
                </p>
              </div>

              <div>
                <p>
                  {' '}
                  <b>{current && current.policy_number}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.inception_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.expiry_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.mv_registration}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.insurer}</b>
                </p>
              </div>
            </div>

            <div
              className='column'
              style={{
                display: 'inline',
                width: '33.3%',
                float: 'right',
                margin: '0 auto',
                padding: '40px',
              }}
            >
              <div>
                <p>
                  <b>{current && current.insured_name}</b>
                </p>
              </div>

              <div>
                <p>
                  {' '}
                  <b>{current && current.policy_number}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.inception_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.expiry_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.mv_registration}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.insurer}</b>
                </p>
              </div>
            </div>

            <div
              className='column'
              style={{
                display: 'inline',
                width: '33.3%',
                float: 'right',
                margin: '0 auto',
                padding: '40px',
              }}
            >
              <div>
                <p>
                  <b>{current && current.insured_name}</b>
                </p>
              </div>

              <div>
                <p>
                  {' '}
                  <b>{current && current.policy_number}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.inception_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.expiry_date}</b>
                </p>
              </div>

              <div>
                <p>
                  <b>{current && current.mv_registration}</b>
                </p>
              </div>
              <div>
                <p>
                  <b>{current && current.insurer}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div style={{ display: 'none' }}>
      <ComponentToPrint ref={componentRef} />
    </div>
  );
};

export default AllInsurances;
