import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import AuthContext from '../../context/auth/authContext';

const TransactionsStaff = (props) => {
  const authContext = useContext(AuthContext);

  const {
    user,

    error,
    loaduser,

    isAuthenticated,
    logout,
    loadUsers,
  } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/transactionStaff');
    }
    loaduser();
    loadUsers();

    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const onLogout = () => {
    logout();
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>
                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/admin/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/admin/change-password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {user && user.role == 'admin' ? (
              <div class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-12'>
                    <h3>
                      <b>Transactions Report For Staff</b>
                      <Link to='/summary'>
                        <button className='btn btn-warning btn-sm rounded-0 float-right'>
                          <i className='fas fa-print  '>print</i>
                        </button>
                      </Link>
                      <div class='float-right'>&nbsp;</div>
                      <Link to='/summary'>
                        <button className='btn btn-primary btn-sm rounded-0 float-right'>
                          <i className='fas fa-download  '>pdf</i>
                        </button>
                      </Link>
                      <div class='float-right'>&nbsp;</div>

                      <Link to='/summary'>
                        <button className='btn btn-success btn-sm rounded-0 float-right'>
                          <i className='fas fa-download  '>csv</i>
                        </button>
                      </Link>
                    </h3>
                  </div>
                </div>
                <div class='card shadow mb-4'>
                  <div className='card-body'>
                    <form>
                      <div class='form-row'>
                        <div className='form-group col-md-3'></div>

                        <div className='row'>
                          <div className='col'>
                            <Link
                              to='/transactionStaff/daily'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Daily Transactions
                              </button>
                            </Link>
                          </div>
                          <div className='col'>
                            <Link
                              to='/transactionStaff/weekly'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Weekly Transactions
                              </button>
                            </Link>
                          </div>

                          <div className='col'>
                            <Link
                              to='/transactionStaff/monthly'
                              style={{ textDecoration: 'none' }}
                            >
                              <button className='btn btn-primary btn-block btn-sm'>
                                Monthly Transactions
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div> &nbsp; &nbsp; &nbsp; &nbsp;</div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col'>Created</th>
                          <th scope='col'>Insured</th>
                          <th scope='col'>Policy Number</th>

                          <th scope='col'>Inception Date</th>
                          <th scope='col'>Expiry Date</th>
                          <th scope='col'>Mv Registration</th>
                          <th scope='col'>Tonnage</th>
                          <th scope='col'>Insurer</th>

                          <th scope='col'>Amount</th>
                          <th scope='col'>Status</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <h1>403 | THIS ACTION IS UNAUTHORIZED</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default TransactionsStaff;
