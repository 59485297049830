import {
  INSURANCE_ERROR,
  ALL_INSURANCE_LOADED,
  EXITED_INSURANCE_LOADED,
  ACTIVE_INSURANCE_LOADED,
  EXPIRING_INSURANCE_LOADED,
  EXPIRED_INSURANCE_LOADED,
  ADD_INSURANCE,
  UPDATE_INSURANCE,
  DELETE_INSURANCE,
  EXIT_INSURANCE,
  EXPIRY_REPORT,
  SALES_REPORT,
  DAILY_TRANSACTION_STAFF,
  WEEKLY_TRANSACTION_STAFF,
  MONTHLY_TRANSACTION_STAFF,
  INSURANCE_SEARCH,
  EXPIRING_INSURANCE_WEEK,
  EXPIRING_INSURANCE_MONTH,
  DAILY_TRANSACTION,
  WEEKLY_TRANSACTION,
  MONTHLY_TRANSACTION,
  CLEAR_ERRORS,
  RENEW_INSURANCE,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case INSURANCE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ALL_INSURANCE_LOADED:
      let allInsurance = state.insurance;
      let loadInsurance = action.payload;

      let ids = loadInsurance.map((c) => c.id);
      loadInsurance = loadInsurance.concat(
        allInsurance.filter(({ id }) => !ids.includes(id))
      );

      loadInsurance.sort(({ createdAt: aId }, { createdAt: bId }) => aId - bId);

      return {
        ...state,

        loading: false,

        insurance: loadInsurance,

        countAllInsurance: action.count,
      };

    case EXITED_INSURANCE_LOADED:
      let exitedInsurance = state.insurance_exit;
      let loadExitedInsurance = action.payload;

      let idsExited = loadExitedInsurance.map((c) => c.id);
      loadExitedInsurance = loadExitedInsurance.concat(
        exitedInsurance.filter(({ id }) => !idsExited.includes(id))
      );

      loadExitedInsurance.sort(
        ({ createdAt: aId }, { createdAt: bId }) => aId - bId
      );
      return {
        ...state,

        loading: false,
        insurance_exit: loadExitedInsurance,
        countExitedInsurance: action.count,
      };
    case INSURANCE_SEARCH:
      return {
        ...state,

        loading: false,
        search: action.payload,
      };

    case ACTIVE_INSURANCE_LOADED:
      let activeInsurance = state.insurance_active;
      let loadActiveInsurance = action.payload;

      let Activeids = loadActiveInsurance.map((x) => x.id);

      loadActiveInsurance = loadActiveInsurance.concat(
        activeInsurance.filter(({ id }) => !Activeids.includes(id))
      );

      loadActiveInsurance.sort(({ createdAt: aId }, { id: bId }) => aId - bId);

      return {
        ...state,

        loading: false,

        insurance_active: loadActiveInsurance,

        countActiveInsurance: action.count,
      };
    case EXPIRED_INSURANCE_LOADED:
      let expiredInsurance = state.insurance_expired;
      let loadExpiredInsurance = action.payload;

      let idsExpired = loadExpiredInsurance.map((c) => c.id);
      loadExpiredInsurance = loadExpiredInsurance.concat(
        expiredInsurance.filter(({ id }) => !idsExpired.includes(id))
      );

      loadExpiredInsurance.sort(({ id: aId }, { id: bId }) => aId - bId);
      return {
        ...state,

        loading: false,
        insurance_expired: loadExpiredInsurance,
        countExpiredInsurance: action.count,
      };

    case EXPIRING_INSURANCE_LOADED:
      let expiringInsurance = state.insurance_expiring;
      let loadExpiringInsurance = action.payload;

      let idsExpiring = loadExpiringInsurance.map((c) => c.id);
      loadExpiringInsurance = loadExpiringInsurance.concat(
        expiringInsurance.filter(({ id }) => !idsExpiring.includes(id))
      );

      loadExpiringInsurance.sort(({ id: aId }, { id: bId }) => aId - bId);
      return {
        ...state,

        loading: false,
        insurance_expiring: loadExpiringInsurance,
        countExpiring1: action.count,
      };

    case EXPIRING_INSURANCE_WEEK:
      let expiringInsuranceWeek = state.insurance_expiring_week;
      let loadExpiringInsuranceWeek = action.payload;

      let idsExpiringWeek = loadExpiringInsuranceWeek.map((c) => c.id);
      loadExpiringInsuranceWeek = loadExpiringInsuranceWeek.concat(
        expiringInsuranceWeek.filter(({ id }) => !idsExpiringWeek.includes(id))
      );

      loadExpiringInsuranceWeek.sort(({ id: aId }, { id: bId }) => aId - bId);
      return {
        ...state,

        loading: false,
        insurance_expiring_week: loadExpiringInsuranceWeek,

        countExpiring7: action.count,
      };
    case EXPIRING_INSURANCE_MONTH:
      let expiringInsuranceMonth = state.insurance_expiring_month;
      let loadExpiringInsuranceMonth = action.payload;

      let idsExpiringMonth = loadExpiringInsuranceMonth.map((c) => c.id);
      loadExpiringInsuranceMonth = loadExpiringInsuranceMonth.concat(
        expiringInsuranceMonth.filter(
          ({ id }) => !idsExpiringMonth.includes(id)
        )
      );

      loadExpiringInsuranceMonth.sort(({ id: aId }, { id: bId }) => aId - bId);
      return {
        ...state,

        loading: false,
        insurance_expiring_month: loadExpiringInsuranceMonth,
        countExpiring30: action.count,
      };

    case ADD_INSURANCE:
      return {
        ...state,
        insurance: [...state.insurance, action.payload],
        error: 'none',
        loading: false,
      };

    case RENEW_INSURANCE:
      return {
        ...state,
        insurance: [...state.insurance, action.payload],
        error: 'none',
        loading: false,
      };

    case UPDATE_INSURANCE:
      return {
        ...state,
        insurance: state.insurance.map((insurance) =>
          insurance.id === action.payload.id ? action.payload : insurance
        ),
        error: 'none',
        loading: false,
      };

    case DELETE_INSURANCE:
      return {
        ...state,
        insurance: state.insurance.filter(
          (insurance) => insurance.id !== action.payload
        ),
        loading: false,
      };

    case EXIT_INSURANCE:
      return {
        ...state,

        loading: false,
      };
    case EXPIRY_REPORT:
      return {
        ...state,
        expiry: action.payload,
        startDate: action.startDate,
        endDate: action.endDate,
        loading: false,
      };

    case SALES_REPORT:
      return {
        ...state,
        sales: action.payload,
        startDate: action.startDate,
        endDate: action.endDate,
        loading: false,
      };
    case DAILY_TRANSACTION_STAFF:
      return {
        ...state,

        loading: false,
        dailyTransaction: action.payload,
      };
    case WEEKLY_TRANSACTION_STAFF:
      return {
        ...state,

        loading: false,
        weeklyTransaction: action.payload,
      };
    case MONTHLY_TRANSACTION_STAFF:
      return {
        ...state,

        loading: false,
        monthlyTransaction: action.payload,
      };

    case DAILY_TRANSACTION:
      return {
        ...state,

        loading: false,
        dailyTransactionAll: action.payload,
        countDailyTransaction: action.count,
      };
    case WEEKLY_TRANSACTION:
      return {
        ...state,

        loading: false,
        weeklyTransactionAll: action.payload,
        countWeeklyTransaction: action.count,
      };
    case MONTHLY_TRANSACTION:
      return {
        ...state,

        loading: false,
        monthlyTransactionAll: action.payload,
        countMonthlyTransaction: action.count,
      };

    default:
      return state;
  }
};
