import React, { useReducer } from 'react';
import axios from 'axios';
import InsuranceContext from './insuranceContext';
import insuranceReducer from './insuranceReducer';

import {
  INSURANCE_ERROR,
  ALL_INSURANCE_LOADED,
  EXITED_INSURANCE_LOADED,
  ACTIVE_INSURANCE_LOADED,
  EXPIRING_INSURANCE_LOADED,
  EXPIRED_INSURANCE_LOADED,
  ADD_INSURANCE,
  UPDATE_INSURANCE,
  DELETE_INSURANCE,
  EXIT_INSURANCE,
  EXPIRY_REPORT,
  SALES_REPORT,
  DAILY_TRANSACTION_STAFF,
  WEEKLY_TRANSACTION_STAFF,
  MONTHLY_TRANSACTION_STAFF,
  INSURANCE_SEARCH,
  EXPIRING_INSURANCE_MONTH,
  EXPIRING_INSURANCE_WEEK,
  DAILY_TRANSACTION,
  WEEKLY_TRANSACTION,
  MONTHLY_TRANSACTION,
  CLEAR_ERRORS,
  RENEW_INSURANCE,
} from '../types';
const InsuranceState = (props) => {
  const initialState = {
    loading: true,
    insurance: [],
    insurance_active: [],
    insurance_exit: [],
    insurance_expired: [],
    insurance_expiring: [],
    insurance_expiring_week: [],
    insurance_expiring_month: [],
    countAllInsurance: 0,
    countActiveInsurance: 0,
    countExpiredInsurance: 0,
    countExpiring30: 0,
    countExpiring7: 0,
    countExitedInsurance: 0,
    countDailyTransaction: 0,
    countWeeklyTransaction: 0,
    countMonthlyTransaction: 0,
    startDate: null,
    endDate: null,
    error: null,
    current: null,
    expiry: [],
    sales: [],
    dailyTransaction: [],
    weeklyTransaction: [],
    monthlyTransaction: [],

    dailyTransactionAll: [],
    weeklyTransactionAll: [],
    monthlyTransactionAll: [],

    PAGE_NUMBER: 0,
    search: [],
  };
  const [state, dispatch] = useReducer(insuranceReducer, initialState);

  //load all insurance
  const getAllInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/all?page=${page}&size=50`
      );
      dispatch({
        type: ALL_INSURANCE_LOADED,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search ALL INSURANCE
  const searchInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search Active INSURANCE
  const searchActiveInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/active?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search ALL INSURANCE
  const searchExitInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/exit?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search EXPIRED INSURANCE
  const searchExpiredInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/expired?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search EXPIRING DAY INSURANCE
  const searchExpiringDayInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/expiring/day?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search EXPIRING IN A WEEK INSURANCE
  const searchExpiringWeekInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/expiring/week?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //search EXPIRING MONTH INSURANCE
  const searchExpiringMonthInsurance = async (searchField) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/search/expiring/month?searchField=${searchField}`
      );
      dispatch({
        type: INSURANCE_SEARCH,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //load EXITED insurance
  const getExitedInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/exited?page=${page}&size=50`
      );
      dispatch({
        type: EXITED_INSURANCE_LOADED,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };
  //load ACTIVE insurance
  const getActiveInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/active?page=${page}&size=50`
      );
      dispatch({
        type: ACTIVE_INSURANCE_LOADED,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };
  //load EXPIRED insurance
  const getExpiredInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/expired?page=${page}&size=50`
      );
      dispatch({
        type: EXPIRED_INSURANCE_LOADED,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //load EXPIRING insurance
  const getDayExpiringInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/expiring/day?page=${page}&size=50`
      );
      dispatch({
        type: EXPIRING_INSURANCE_LOADED,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };
  const getWeekExpiringInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/expiring/week?page=${page}&size=50`
      );
      dispatch({
        type: EXPIRING_INSURANCE_WEEK,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };
  const getMonthExpiringInsurance = async (page) => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/expiring/month?page=${page}&size=50`
      );
      dispatch({
        type: EXPIRING_INSURANCE_MONTH,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };
  //register user
  const addInsurance = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance',
        formData,
        config
      );
      dispatch({
        type: ADD_INSURANCE,
        payload: res.data,
      });

      getAllInsurance(0);
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 2000);
    }
  };

  //register user
  const renewInsurance = async (formData, current) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        `https://api.mahir.co.ke/insurance/renew/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: RENEW_INSURANCE,
        payload: res.data,
      });

      // getAllInsurance(0);
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 2000);
    }
  };

  const updateInsurance = async (formData, current) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(
        `https://api.mahir.co.ke/insurance/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_INSURANCE,
        payload: res.data,
      });
      getAllInsurance();
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 2000);
    }
  };

  //Delete User
  const deleteInsurance = async (id) => {
    try {
      await axios.delete(`https://api.mahir.co.ke/insurance/${id}`);

      dispatch({
        type: DELETE_INSURANCE,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const exitInsurance = async (current) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(
        `https://api.mahir.co.ke/insurance/exit/${current.id}`,

        config
      );
      dispatch({
        type: EXIT_INSURANCE,
        payload: res.data,
      });
      getAllInsurance();
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const expiryReport = async (formData) => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/report/expired',
        formData,
        config
      );
      dispatch({
        type: EXPIRY_REPORT,
        payload: res.data,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //register user
  const salesReport = async (formData) => {
    const config = {
      headers: {
        Accept: 'multipart/form-data',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/insurance/report/sale',
        formData,
        config
      );
      dispatch({
        type: SALES_REPORT,
        payload: res.data,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
        payload: err.response.data.msg,
      });
    }
  };

  //TRANSACTIONS FOR ALL

  const getDailyTransactionAll = async () => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get('https://api.mahir.co.ke/insurance/daily');
      dispatch({
        type: DAILY_TRANSACTION,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  const getWeeklyTransactionAll = async () => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get('https://api.mahir.co.ke/insurance/weekly');
      dispatch({
        type: WEEKLY_TRANSACTION,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  const getMonthlyTransactionAll = async () => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get('https://api.mahir.co.ke/insurance/monthly');
      dispatch({
        type: MONTHLY_TRANSACTION,
        payload: res.data.rows,
        count: res.data.count,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //TRANSACTION FOR STAFF
  //load daily report
  const getDailyTransaction = async (current) => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/daily/${current.id}`
      );
      dispatch({
        type: DAILY_TRANSACTION_STAFF,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //load weekly report
  const getWeeklyTransaction = async (current) => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/weekly/${current.id}`
      );
      dispatch({
        type: WEEKLY_TRANSACTION_STAFF,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //load monthly report
  const getMonthlyTransaction = async (current) => {
    //@todo setAuthToken
    // setAuthToken(localStorage.token);
    try {
      const res = await axios.get(
        `https://api.mahir.co.ke/insurance/monthly/${current.id}`
      );
      dispatch({
        type: MONTHLY_TRANSACTION_STAFF,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INSURANCE_ERROR,
      });
    }
  };

  //Clear Error
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <InsuranceContext.Provider
      value={{
        loading: state.loading,
        insurance: state.insurance,
        insurance_active: state.insurance_active,
        insurance_exit: state.insurance_exit,
        insurance_expired: state.insurance_expired,
        insurance_expiring: state.insurance_expiring,
        insurance_expiring_week: state.insurance_expiring_week,
        insurance_expiring_month: state.insurance_expiring_month,

        error: state.error,
        current: state.current,
        expiry: state.expiry,
        sales: state.sales,
        dailyTransaction: state.dailyTransaction,
        monthlyTransaction: state.monthlyTransaction,
        weeklyTransaction: state.weeklyTransaction,
        PAGE_NUMBER: state.PAGE_NUMBER,

        countAllInsurance: state.countAllInsurance,
        search: state.search,
        countActiveInsurance: state.countActiveInsurance,

        countExpiredInsurance: state.countExpiredInsurance,
        countExpiring30: state.countExpiring30,
        countExpiring7: state.countExpiring7,
        countExpiring1: state.countExpiring1,
        countExitedInsurance: state.countExitedInsurance,

        dailyTransactionAll: state.dailyTransactionAll,
        monthlyTransactionAll: state.monthlyTransactionAll,
        weeklyTransactionAll: state.weeklyTransactionAll,

        countDailyTransaction: state.countDailyTransaction,
        countWeeklyTransaction: state.countWeeklyTransaction,
        countMonthlyTransaction: state.countMonthlyTransaction,

        startDate: state.startDate,
        endDate: state.endDate,

        getAllInsurance,
        getActiveInsurance,
        getExitedInsurance,
        getExpiredInsurance,
        getDayExpiringInsurance,
        getWeekExpiringInsurance,
        getMonthExpiringInsurance,
        addInsurance,
        updateInsurance,
        deleteInsurance,
        exitInsurance,
        expiryReport,
        salesReport,
        getDailyTransaction,
        getWeeklyTransaction,
        getMonthlyTransaction,
        searchInsurance,
        searchActiveInsurance,
        searchExitInsurance,
        searchExpiredInsurance,
        searchExpiringDayInsurance,
        searchExpiringWeekInsurance,
        searchExpiringMonthInsurance,
        getDailyTransactionAll,
        getWeeklyTransactionAll,
        getMonthlyTransactionAll,
        clearErrors,
        renewInsurance,
      }}
    >
      {props.children}
    </InsuranceContext.Provider>
  );
};
export default InsuranceState;
