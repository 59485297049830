import { SET_ALERT, REMOVE_ALERT, INDIVIDUAL_SMS } from '../types';

export default (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alert: [],
      };

    case INDIVIDUAL_SMS:
      return {
        ...state,

        loading: false,
      };

    default:
      return state;
  }
};
