import React, { useReducer } from 'react';
import axios from 'axios';
import LogbookContext from './logbookContext';
import logbookReducer from './logbookReducer';

import {
  LOGBOOK_ERROR,
  ADD_LOGBOOK_SUCCESS,
  LOGBOOK_LOADED,
  UPDATE_LOGBOOK,
  DELETE_LOGBOOK,
  CLEAR_ERRORS,
} from '../types';
const LogbookState = (props) => {
  const initialState = {
    loading: true,
    logbook: null,
    error: null,
    current: null,
  };
  const [state, dispatch] = useReducer(logbookReducer, initialState);

  //load logbook
  const getLogbook = async () => {
    //@todo setAuthToken
    //setAuthToken(localStorage.token);
    try {
      const res = await axios.get('https://api.mahir.co.ke/logbook');
      dispatch({
        type: LOGBOOK_LOADED,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: LOGBOOK_ERROR,
      });
    }
  };

  //register user
  const addLogbook = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        'https://api.mahir.co.ke/logbook',
        formData,
        config
      );
      dispatch({
        type: ADD_LOGBOOK_SUCCESS,
        payload: res.data,
      });
      getLogbook();
    } catch (err) {
      dispatch({
        type: LOGBOOK_ERROR,
        payload: err.response.data.msg,
      });
      console.log(err.response.data.msg);
      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 2000);
    }
  };

  const updateLogbook = async (formData, current) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.put(
        `https://api.mahir.co.ke/logbook/${current.id}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_LOGBOOK,
        payload: res.data,
      });
      getLogbook();
    } catch (err) {
      dispatch({
        type: LOGBOOK_ERROR,
        payload: err.response.data.msg,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR_ERRORS,
        });
        //this.setState({ alert: null });
      }, 2000);
    }
  };

  //Delete User
  const deleteLogbook = async (id) => {
    try {
      await axios.delete(`https://api.mahir.co.ke/logbook/${id}`);

      dispatch({
        type: DELETE_LOGBOOK,
        payload: id,
      });
    } catch (err) {
      dispatch({
        type: LOGBOOK_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //Clear Error
  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS,
    });
  };

  return (
    <LogbookContext.Provider
      value={{
        loading: state.loading,
        logbook: state.logbook,

        error: state.error,
        current: state.current,

        getLogbook,
        addLogbook,
        updateLogbook,
        deleteLogbook,
        clearErrors,
      }}
    >
      {props.children}
    </LogbookContext.Provider>
  );
};
export default LogbookState;
