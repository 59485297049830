import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import Sidebar from '../layout/Sidebar';
import AuthContext from '../../context/auth/authContext';
import InsuranceContext from '../../context/insurance/insuranceContext';

import moment from 'moment';
import { CSVLink } from 'react-csv';

const ExpiredReport = (props) => {
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const authContext = useContext(AuthContext);
  const insuranceContext = useContext(InsuranceContext);

  const {
    isAuthenticated,
    logout,
    setCurrent,
    user,

    loaduser,
  } = authContext;
  let { expiryReport, expiry } = insuranceContext;
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/expiredReport');
    }
    loaduser();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [register, setRegister] = useState({
  //   startDate: '',
  //   endDate: '',
  // });
  // let { startDate, endDate } = register;
  // const onChange = (e) =>
  //   setRegister({
  //     ...register,
  //     [e.target.name]: e.target.value,
  //     //[e.target.SeatNumber]: e.target.value.split(","),
  //   });
  const onLogout = () => {
    logout();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    expiryReport({
      startDate,
      endDate,
    });

    props.history.push('/expiredReport');
  };

  const headers = [
    { label: 'Created', key: 'createdAt' },
    { label: 'Expiry Date', key: 'expiry_date' },
    { label: 'Insured', key: 'insured_name' },
    { label: 'Policy Number', key: 'policy_number' },
    { label: 'Inception', key: 'inception_date' },
    { label: 'Mv Registration', key: 'mv_registration' },
    { label: 'Tonnage', key: 'tonnage' },
    { label: 'Date of issue', key: 'createdAt' },
    { label: 'Contact', key: 'phone' },
  ];

  expiry = expiry.map((row) => ({
    ...row,
    createdAt: moment(row.createdAt).format('YYYY/MM/DD'),
  }));

  const csvReport = {
    filename: 'Mahir Insurance Agency LTD.CSV',
    headers: headers,
    data: expiry,
  };

  //download pdf

  //pdf

  // class ComponentToPrint extends React.Component {
  //   render() {
  //     return (
  //       <div style={{ fontSize: '30px' }} id='pdf'>
  //         <center id='top'>
  //           <div class='info'>
  //             <h1>
  //               <b>Mahir Insurance Agency LTD</b>{' '}
  //             </h1>
  //           </div>
  //         </center>
  //         &nbsp;
  //         <div>
  //           <center>
  //             <header>
  //               <h4>
  //                 <b>
  //                   Expiry Report From {startDate} to {endDate}
  //                 </b>
  //               </h4>
  //             </header>
  //           </center>
  //         </div>
  //         &nbsp;
  //         <div id='bot'>
  //           <div id='table'>
  //             <table className='table'>
  //               <thead>
  //                 <th scope='col'>
  //                   <b>CREATED</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>INSURED</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>POLICY NUMBER</b>
  //                 </th>

  //                 <th scope='col'>
  //                   <b>INCEPTION DATE</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>EXPIRY DATE</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>MV REGISTRATION</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>TONNAGE</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>INSURER</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>DATE OF ISSUE</b>
  //                 </th>

  //                 <th scope='col'>
  //                   <b>AMOUNT</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>PHONE</b>
  //                 </th>
  //                 <th scope='col'>
  //                   <b>STATUS</b>
  //                 </th>
  //               </thead>
  //               <tbody>
  //                 {expiry &&
  //                   expiry.map((expiry) => {
  //                     return (
  //                       <Fragment>
  //                         <tr>
  //                           <td>
  //                             {new Date(expiry.createdAt).toLocaleDateString(
  //                               'en-GB'
  //                             )}
  //                           </td>
  //                           <td>{expiry.insured_name}</td>

  //                           <td>{expiry.policy_number}</td>
  //                           <td>
  //                             {' '}
  //                             {new Date(
  //                               expiry.inception_date
  //                             ).toLocaleDateString('zh-Hans-CN')}
  //                           </td>
  //                           <td>
  //                             {' '}
  //                             {new Date(expiry.expiry_date).toLocaleDateString(
  //                               'en-GB'
  //                             )}
  //                           </td>
  //                           <td>{expiry.mv_registration}</td>
  //                           <td>{expiry.tonnage}</td>
  //                           <td>{expiry.insurer}</td>
  //                           <td>
  //                             {' '}
  //                             {new Date(expiry.createdAt).toLocaleDateString(
  //                               'en-GB'
  //                             )}
  //                           </td>
  //                           <td>{expiry.amount}</td>

  //                           <td>{expiry.phone}</td>

  //                           <td>{expiry.status}</td>
  //                         </tr>
  //                       </Fragment>
  //                     );
  //                   })}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // }
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };

  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>
                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {user && user.role == 'admin' ? (
              <div class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-12'>
                    <h3>
                      <b>Expired Report</b>
                      <Link to='/expired/print'>
                        {/* <div style={{ display: 'none' }}>
                          <ComponentToPrint ref={componentRef} />
                        </div> */}

                        <button
                          onClick={() => setCurrent(expiry)}
                          type='submit'
                          className='btn btn-warning btn-sm rounded-0 float-right'
                        >
                          <i className='fas fa-print  '>print</i>
                        </button>
                      </Link>
                      <div class='float-right'>&nbsp;</div>

                      <div class='float-right'>&nbsp;</div>

                      <CSVLink
                        {...csvReport}
                        className='btn btn-success btn-sm rounded-0 float-right'
                      >
                        <i className='fas fa-download  '>csv</i>
                      </CSVLink>
                    </h3>
                  </div>
                </div>
                <div class='card shadow mb-4'>
                  <div className='card-body'>
                    <form onSubmit={onSubmit}>
                      <div class='form-row'>
                        <div className='col'>
                          <label htmlFor=''>Start Date</label>
                          <div class='input-group date' id='datetimepicker2'>
                            <DatePicker
                              className='form-control'
                              dateFormat='yyyy/MM/dd'
                              onInputClick={() => setStartDate(new Date())}
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            />

                            {/* <input
                              type='date'
                              class='form-control form-control-sm'
                              name='startDate'
                              value={startDate}
                              onChange={onChange}
                              required
                            /> */}
                            <span class='input-group-addon'>
                              <span class='glyphicon glyphicon-calendar'></span>
                            </span>
                          </div>
                        </div>

                        <div className='col'>
                          <label htmlFor=''>End Date</label>
                          <div class='input-group date' id='datetimepicker2'>
                            <DatePicker
                              className='form-control'
                              dateFormat='yyyy/MM/dd'
                              onInputClick={() => setEndDate(new Date())}
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                            />
                            {/* <input
                              type='date'
                              class='form-control form-control-sm'
                              name='endDate'
                              value={endDate}
                              onChange={onChange}
                              required
                            /> */}
                            <span class='input-group-addon'>
                              <span class='glyphicon glyphicon-calendar'></span>
                            </span>
                          </div>
                        </div>

                        <div class='col'>
                          <label htmlFor=''>Search</label>
                          <button
                            className='btn btn-primary btn-block btn-sm'
                            type='submit'
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                    <div> &nbsp; &nbsp; &nbsp; &nbsp;</div>
                    <div>
                      <div className='table-responsive'>
                        <table
                          class='table table-bordered'
                          id='dataTable'
                          width='100%'
                          cellspacing='0'
                        >
                          <thead>
                            <tr>
                              <th scope='col'>Created</th>
                              <th scope='col'>Insured</th>
                              <th scope='col'>Policy Number</th>

                              <th scope='col'>Inception Date</th>
                              <th scope='col'>Expiry Date</th>
                              <th scope='col'>Mv Registration</th>

                              <th scope='col'>Insurer</th>

                              <th scope='col'>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {expiry &&
                              expiry.map((expiry) => {
                                return (
                                  <Fragment>
                                    <tr>
                                      <td>{expiry.createdAt}</td>
                                      <td>{expiry.insured_name}</td>

                                      <td>{expiry.policy_number}</td>
                                      <td>
                                        {' '}
                                        {new Date(
                                          expiry.inception_date
                                        ).toLocaleDateString('zh-Hans-CN')}
                                      </td>
                                      <td>
                                        {' '}
                                        {new Date(
                                          expiry.expiry_date
                                        ).toLocaleDateString('zh-Hans-CN')}
                                      </td>
                                      <td>{expiry.mv_registration}</td>
                                      <td>{expiry.insurer}</td>
                                      <td>{expiry.amount}</td>
                                    </tr>
                                  </Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <h1>403 | THIS ACTION IS UNAUTHORIZED</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default ExpiredReport;
