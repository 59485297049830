/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useEffect } from 'react';

import { Link } from 'react-router-dom';
import AuthContext from '../../context/auth/authContext';
import mahir from '../../images/mia.PNG';

const Sidebar = (props) => {
  const authContext = useContext(AuthContext);

  const {
    isAuthenticated,

    user,

    loaduser,
  } = authContext;

  useEffect(() => {
    loaduser();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  if (user && user.role === 'admin') {
    return (
      <ul
        class='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'
        id='accordionSidebar'
      >
        <a
          class='sidebar-brand d-flex align-items-center justify-content-center'
          href='https://mahir.co.ke'
        >
          <div class='sidebar-brand-icon '>
            {<img className='mr-0' style={{ width: '30px' }} src={mahir} />}{' '}
            {user && user.user_name}
          </div>
          {/* <div class='sidebar-brand-text mx-3'>
                {user && user.user_name} <sup></sup>
              </div> */}
        </a>
        <hr class='sidebar-divider my-0' />

        <li class='nav-item active'>
          <Link to='/' className='nav-link'>
            <i class='fas fa-fw fa-tachometer-alt'></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <hr class='sidebar-divider' />

        <li class='nav-item'>
          <a
            class='nav-link '
            href='#'
            data-toggle='collapse'
            data-target='#collapseUtilities'
            aria-expanded='true'
            aria-controls='collapseUtilities'
          >
            <i class='fas fa-lock'></i>
            <span>INSURANCES</span>
          </a>
          <div
            id='collapseUtilities'
            class='collapse'
            aria-labelledby='headingUtilities'
            data-parent='#accordionSidebar'
          >
            <div class='bg-white py-2 collapse-inner rounded'>
              <Link to='/Insurances' className='collapse-item'>
                All Insurances
              </Link>
              <Link to='/exited' className='collapse-item'>
                Exited Insurances
              </Link>
              <Link to='/active' className='collapse-item'>
                Active Insurances
              </Link>
              <Link to='/expiring' className='collapse-item'>
                Expiring Insurances
              </Link>
              <Link to='/expired' className='collapse-item'>
                Expired Insurances
              </Link>
            </div>
          </div>
        </li>
        {/* <li class='nav-item'>
              <a
                class='nav-link collapsed'
                href='#'
                data-toggle='collapse'
                data-target='#collapsePages'
                aria-expanded='true'
                aria-controls='collapsePages'
             >
                <i class='fas fa-bus'></i>
                <span>Categorises </span>
              </a>
              <div
                id='collapsePages'
                class='collapse'
                aria-labelledby='headingPages'
                data-parent='#accordionSidebar'
              >
                <div class='bg-white py-2 collapse-inner rounded'>
                  <Link to='/trips/location' className='collapse-item'>
                    Manage categories
                  </Link>
                  
                </div>
              </div>
            </li> */}

        <li class='nav-item'>
          <a
            class='nav-link collapsed'
            href='#'
            data-toggle='collapse'
            data-target='#collapsePages2'
            aria-expanded='true'
            aria-controls='collapsePages2'
          >
            <i class='fas fa-list'></i>
            <span>REPORTS</span>
          </a>
          <div
            id='collapsePages2'
            class='collapse'
            aria-labelledby='headingPages'
            data-parent='#accordionSidebar'
          >
            <div class='bg-white py-2 collapse-inner rounded'>
              <Link to='/Transactional' className='collapse-item'>
                Transaction Reports
              </Link>
              <Link to='/expiredReport' className='collapse-item'>
                Expired Reports
              </Link>
              <Link to='/sales' className='collapse-item'>
                Sales Reports
              </Link>
            </div>
          </div>
        </li>

        <li class='nav-item'>
          <Link to='/Logbooks' className='nav-link'>
            <i class='fas fa-book'></i>
            <span>LOGBOOK</span>
          </Link>
        </li>

        <li class='nav-item'>
          <a
            class='nav-link collapsed'
            href='#'
            data-toggle='collapse'
            data-target='#collapsePages4'
            aria-expanded='true'
            aria-controls='collapsePages4'
          >
            <i class='fas fa-users'></i>
            <span>STAFF MANAGEMENT</span>
          </a>
          <div
            id='collapsePages4'
            class='collapse'
            aria-labelledby='headingPages'
            data-parent='#accordionSidebar'
          >
            <div class='bg-white py-2 collapse-inner rounded'>
              <Link to='/RegisteredUsers' className='collapse-item'>
                User Management
              </Link>
              <Link to='/allStaff' className='collapse-item'>
                Staff Transaction Reports
              </Link>
              <Link to='/staff' className='collapse-item'>
                Staff Login Report
              </Link>
            </div>
          </div>
        </li>

        <li class='nav-item'>
          <Link to='/sms' className='nav-link'>
            <i class='fas fa-paper-plane'></i>
            <span>SMS MODULE</span>
          </Link>
        </li>

        {/* <li class='nav-item'>
              <Link to='/system' className='nav-link'>
                <i class='fas fa-cog'></i>
                <span>SYSTEM SETTINGS</span>
              </Link>
            </li> */}

        <hr class='sidebar-divider d-none d-md-block' />
      </ul>
    );
  } else {
    return (
      <ul
        class='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'
        id='accordionSidebar'
      >
        <a
          class='sidebar-brand d-flex align-items-center justify-content-center'
          href='https://mahir.co.ke'
        >
          <div class='sidebar-brand-icon '>
            {<img className='mr-0' style={{ width: '30px' }} src={mahir} />}{' '}
            {user && user.user_name}
          </div>
          {/* <div class='sidebar-brand-text mx-3'>
                {user && user.user_name} <sup></sup>
              </div> */}
        </a>
        <hr class='sidebar-divider my-0' />

        <li class='nav-item active'>
          <Link to='/' className='nav-link'>
            <i class='fas fa-fw fa-tachometer-alt'></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <hr class='sidebar-divider' />

        <li class='nav-item'>
          <a
            class='nav-link '
            href='#'
            data-toggle='collapse'
            data-target='#collapseUtilities'
            aria-expanded='true'
            aria-controls='collapseUtilities'
          >
            <i class='fas fa-lock'></i>
            <span>INSURANCES</span>
          </a>
          <div
            id='collapseUtilities'
            class='collapse'
            aria-labelledby='headingUtilities'
            data-parent='#accordionSidebar'
          >
            <div class='bg-white py-2 collapse-inner rounded'>
              <Link to='/Insurances' className='collapse-item'>
                All Insurances
              </Link>
              <Link to='/exited' className='collapse-item'>
                Exited Insurances
              </Link>
              <Link to='/active' className='collapse-item'>
                Active Insurances
              </Link>
              <Link to='/expiring' className='collapse-item'>
                Expiring Insurances
              </Link>
              <Link to='/expired' className='collapse-item'>
                Expired Insurances
              </Link>
            </div>
          </div>
        </li>

        <li class='nav-item'>
          <Link to='/Logbooks' className='nav-link'>
            <i class='fas fa-book'></i>
            <span>LOGBOOK</span>
          </Link>
        </li>

        {/* <li class='nav-item'>
              <Link to='/system' className='nav-link'>
                <i class='fas fa-cog'></i>
                <span>SYSTEM SETTINGS</span>
              </Link>
            </li> */}

        <hr class='sidebar-divider d-none d-md-block' />
      </ul>
    );
  }
};

export default Sidebar;
