import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Content from './components/layout/Content';
import AllInsurances from './components/Insurances/AllInsurances';
import ActiveInsurances from './components/Insurances/ActiveInsurances';
import ExitedInsurances from './components/Insurances/ExitedInsurances';
import ExpiringInsurances from './components/Insurances/ExpiringInsurances';
import ExpiredInsurances from './components/Insurances/ExpiredInsurances';
import DayExpiry from './components/Insurances/DayExpiry';
import WeekExpiry from './components/Insurances/WeekExpiry';
import MonthExpiry from './components/Insurances/MonthExpiry';

import './App.css';
import RegisteredUsers from './components/Users/RegisteredUsers';
import UserRegistration from './components/Users/UserRegistration';
import UpdateUser from './components/Users/UpdateUser';
import RegisteredLogbooks from './components/Logbook/RegisteredLogbooks';
import SmsModule from './components/sms module/SmsModule';
import CreateLogbook from './components/Logbook/CreateLogbook';
import UpdateLogbook from './components/Logbook/UpdateLogbook';
import CreateInsurance from './components/Insurances/CreateInsurance';
import ViewInsurance from './components/Insurances/ViewInsurance';
import UpdateInsurance from './components/Insurances/UpdateInsurance';
import RenewInsurance from './components/Insurances/RenewInsurance';

import Login from './components/auth/Login';
import Profile from './components/auth/Profile';

import PasswordSetting from './components/auth/PasswordSetting';
import LoginReport from './components/staff/LoginReport';
import SalesReport from './components/report/SalesReport';

import TransactionalReport from './components/report/TransactionalReport';
import ExpiredReport from './components/report/ExpiredReport';

import AllStaffTransactions from './components/staff/AllStaffTransactions';
import TransactionsStaff from './components/staff/TransactionsStaff';
import DailyTransaction from './components/staff/DailyTransaction';
import WeeklyTransaction from './components/staff/WeeklyTransaction';
import MonthlyTransaction from './components/staff/MonthlyTransaction';

import PrintInsurance from './components/Insurances/PrintInsurance';
import PrintReport from './components/report/PrintReport';
import PrintExpired from './components/report/PrintExpired';

import IdleTimerContainer from './components/layout/Timmer';

//states
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';
import LogbookState from './context/logbook/Logbookstate';
import InsuranceState from './context/insurance/InsuranceState';

import PrivateRoute from './components/routing/PrivateRoute';

function App() {
  return (
    <div>
      <IdleTimerContainer></IdleTimerContainer>

      <AuthState>
        <AlertState>
          <LogbookState>
            <InsuranceState>
              <Router>
                <PrivateRoute exact path='/' component={Content}></PrivateRoute>

                <PrivateRoute
                  exact
                  path='/Insurances'
                  component={AllInsurances}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/RegisteredUsers'
                  component={RegisteredUsers}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/userRegistration/create'
                  component={UserRegistration}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/staff'
                  component={LoginReport}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/updateUser/edit'
                  component={UpdateUser}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/Logbooks'
                  component={RegisteredLogbooks}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/addLogbook'
                  component={CreateLogbook}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/updateLogbook'
                  component={UpdateLogbook}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/active'
                  component={ActiveInsurances}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/allStaff'
                  component={AllStaffTransactions}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/sms'
                  component={SmsModule}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/exited'
                  component={ExitedInsurances}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expiring'
                  component={ExpiringInsurances}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expiring/day'
                  component={DayExpiry}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expiring/week'
                  component={WeekExpiry}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expiring/month'
                  component={MonthExpiry}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expired'
                  component={ExpiredInsurances}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/create'
                  component={CreateInsurance}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/renew'
                  component={RenewInsurance}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/edit'
                  component={UpdateInsurance}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/view'
                  component={ViewInsurance}
                ></PrivateRoute>

                <Route exact path='/login' component={Login}></Route>
                <PrivateRoute
                  exact
                  path='/password'
                  component={PasswordSetting}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/profile'
                  component={Profile}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/sales'
                  component={SalesReport}
                ></PrivateRoute>

                <PrivateRoute
                  exact
                  path='/Transactional'
                  component={TransactionalReport}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expiredReport'
                  component={ExpiredReport}
                ></PrivateRoute>

                <PrivateRoute
                  exact
                  path='/transactionStaff'
                  component={TransactionsStaff}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/transactionStaff/daily'
                  component={DailyTransaction}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/transactionStaff/weekly'
                  component={WeeklyTransaction}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/transactionStaff/monthly'
                  component={MonthlyTransaction}
                ></PrivateRoute>

                <PrivateRoute
                  exact
                  path='/insurance/print'
                  component={PrintInsurance}
                ></PrivateRoute>

                <PrivateRoute
                  exact
                  path='/sales/print'
                  component={PrintReport}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path='/expired/print'
                  component={PrintExpired}
                ></PrivateRoute>
              </Router>
            </InsuranceState>
          </LogbookState>
        </AlertState>
      </AuthState>
    </div>
  );
}

export default App;

// import React, { Fragment } from "react";
// import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

// import Content from "./components/layout/Content";

// const App = () => {
//       return(
//         <Fragment>
//           <Switch>
//           <Router exact path='/' component={Content} />
//           </Switch>
//         </Fragment>

//       );
// };

// export default App;
