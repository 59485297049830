import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../layout/Sidebar';

import Spiner from '../layout/Spinner/Spinner';
import AuthContext from '../../context/auth/authContext';

import InsuranceContext from '../../context/insurance/insuranceContext';

const Content = (props) => {
  const authContext = useContext(AuthContext);
  const { logout, isAuthenticated, loadUsers, users, loaduser, loading, user } =
    authContext;
  const insuranceContext = useContext(InsuranceContext);

  const {
    countAllInsurance,
    countActiveInsurance,
    countExpiredInsurance,
    countExpiring30,
    countExpiring7,
    countExpiring1,
    countExitedInsurance,

    countDailyTransaction,
    countWeeklyTransaction,
    countMonthlyTransaction,

    dailyTransactionAll,
    monthlyTransactionAll,
    weeklyTransactionAll,

    getAllInsurance,
    getActiveInsurance,
    getExpiredInsurance,
    getWeekExpiringInsurance,
    getMonthExpiringInsurance,
    getExitedInsurance,
    getDayExpiringInsurance,

    getDailyTransactionAll,
    getWeeklyTransactionAll,
    getMonthlyTransactionAll,
  } = insuranceContext;
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/');
    }

    getAllInsurance(0);
    getActiveInsurance(0);
    getExpiredInsurance(0);
    getWeekExpiringInsurance(0);
    getMonthExpiringInsurance(0);
    getExitedInsurance(0);
    getDayExpiringInsurance(0);

    getDailyTransactionAll();
    getWeeklyTransactionAll();
    getMonthlyTransactionAll();
    loaduser();
    loadUsers();
    // getDailyReport();
    // getWeeklyReport();
    // getMonthlyReport();

    // eslint-disable-next-line
  }, [isAuthenticated, props.history]);

  //loading spinner
  if (loading) {
    return <Spiner />;
  }

  const onLogout = () => {
    logout();
  };

  let totalDailySale = dailyTransactionAll.reduce(function (tot, arr) {
    // return the sum with previous value
    return tot + arr.amount;

    // set initial value as 0
  }, 0);

  let totalWeeklySale = weeklyTransactionAll.reduce(function (tot, arr) {
    // return the sum with previous value
    return tot + arr.amount;

    // set initial value as 0
  }, 0);

  let totalMonthlySale = monthlyTransactionAll.reduce(function (tot, arr) {
    // return the sum with previous value
    return tot + arr.amount;

    // set initial value as 0
  }, 0);

  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        {/* pass sidebar */}
        <Sidebar />

        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div className='input-group-append'>
                    <button className='btn btn-primary' type='button'>
                      <i className='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul className='navbar-nav ml-auto'>
                <div className='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            {user && user.role !== 'admin' ? (
              <div className='container-fluid'>
                <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                  <h1 className='h3 mb-0 text-gray-800'>Dashboard</h1>
                </div>

                <div className='row'>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-circle fa-3x text-warning'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Total insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countAllInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-check fa-3x text-success'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Active Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countActiveInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-times fa-3x text-danger'></i>
                            <div className='text-xs font-weight-bold text-warning text-uppercase mb-1'>
                              Exited Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExitedInsurance}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-exclamation-triangle fa-3x text-danger'></i>
                            <div className='text-xs font-weight-bold text-danger text-uppercase mb-1'>
                              Expired Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiredInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fa fa-calender fa-3x text-info'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (7 days)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring7}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (30 days)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring30}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (1 day)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring1}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Daily Transaction
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countDailyTransaction}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Weekly Transaction
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countWeeklyTransaction}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Daily Sales
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {totalDailySale.toLocaleString()}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Weekly Sales
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {totalWeeklySale.toLocaleString()}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='container-fluid'>
                <div className='d-sm-flex align-items-center justify-content-between mb-4'>
                  <h1 className='h3 mb-0 text-gray-800'>Dashboard</h1>
                </div>

                <div className='row'>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-circle fa-3x text-warning'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Total insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countAllInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-check fa-3x text-success'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Active Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countActiveInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-times fa-3x text-danger'></i>
                            <div className='text-xs font-weight-bold text-warning text-uppercase mb-1'>
                              Exited Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExitedInsurance}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fas fa-exclamation-triangle fa-3x text-danger'></i>
                            <div className='text-xs font-weight-bold text-danger text-uppercase mb-1'>
                              Expired Insurances
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiredInsurance}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i className='fa fa-calender fa-3x text-info'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (7 days)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring7}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (30 days)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring30}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Expiring in (1 day)
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countExpiring1}
                            </div>
                          </div>
                          <div className='col-auto'>
                            <i className='fas fa-calendar-alt fa-3x text-warning -300'></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <i class='fas fa-user fa-3x   text-success'></i>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Users
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {users.length}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-3 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Daily Transaction
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countDailyTransaction}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Weekly Transaction
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countWeeklyTransaction}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Monthly Transaction
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {countMonthlyTransaction}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Daily Sales
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {totalDailySale.toLocaleString()}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Weekly Sales
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {totalWeeklySale.toLocaleString()}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-xl-4 col-md-6 mb-4'>
                    <div className='card border-left-primary shadow h-100 py-2'>
                      <div className='card-body'>
                        <div className='row no-gutters align-items-center'>
                          <div className='col mr-2'>
                            <div className='text-xs font-weight-bold text-primary text-uppercase mb-1'>
                              Monthly Sales
                            </div>
                            <div className='h5 mb-0 font-weight-bold text-gray-800'>
                              {totalMonthlySale.toLocaleString()}
                            </div>
                          </div>
                          <div className='col-auto'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </body>
  );
};

export default Content;
