import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../context/auth/authContext';
import Sidebar from '../layout/Sidebar';

import { Link } from 'react-router-dom';
const PasswordSetting = (props) => {
  const authContext = useContext(AuthContext);

  const {
    user,
    error,
    loaduser,
    changePassword,
    isAuthenticated,
    logout,
    loadUsers,
  } = authContext;

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/password');
    }
    loaduser();
    loadUsers();

    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);
  const [update, setUpdate] = useState({
    CurrentPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
  });

  const { CurrentPassword, NewPassword, ConfirmPassword } = update;
  const onChange = (e) =>
    setUpdate({
      ...update,
      [e.target.name]: e.target.value,
    });
  const onLogout = () => {
    logout();
  };
  const onSubmit = (e) => {
    e.preventDefault();

    changePassword(
      {
        CurrentPassword,
        NewPassword,
        ConfirmPassword,
      },
      user
    );
    props.history.push('/');
  };
  const myFunction = () => {
    let element = document.getElementById('page-top');
    element.classList.toggle('sidebar-toggled');
    let side = document.getElementById('accordionSidebar');
    side.classList.toggle('toggled');
  };
  return (
    <body id='page-top'>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' class='d-flex flex-column'>
          <div id='content'>
            {/* pass Navbar */}

            <nav class='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
              <button
                id='sidebarToggleTop'
                class='btn btn-link d-md-none rounded-circle mr-3'
                onClick={myFunction}
              >
                <i class='fa fa-bars'></i>
              </button>
              <form class='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
                <div class='input-group'>
                  <input
                    type='text'
                    class='form-control bg-light border-0 small'
                    placeholder='Search for...'
                    aria-label='Search'
                    aria-describedby='basic-addon2'
                  />
                  <div class='input-group-append'>
                    <button class='btn btn-primary' type='button'>
                      <i class='fas fa-search fa-sm'></i>
                    </button>
                  </div>
                </div>
              </form>

              <ul class='navbar-nav ml-auto'>
                <div class='topbar-divider d-none d-sm-block'></div>

                <li class='nav-item dropdown no-arrow'>
                  <a
                    class='nav-link dropdown-toggle'
                    href='#/'
                    id='userDropdown'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <span class='mr-2 d-none d-lg-inline text-gray-600 small'>
                      {user && user.user_name}
                    </span>
                    <i class='fas fa-user fa-2x fa-fw mr-2 img-profile rounded-circle'></i>
                  </a>
                  <div
                    class='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                    aria-labelledby='userDropdown'
                  >
                    <Link to='/profile' className='dropdown-item'>
                      <i class='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
                      Profile
                    </Link>

                    <Link to='/password' className='dropdown-item'>
                      <i class='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
                      Password Settings
                    </Link>

                    <div class='dropdown-divider'></div>
                    <a
                      className='dropdown-item'
                      href='/login'
                      onClick={onLogout}
                    >
                      {' '}
                      <i class='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>{' '}
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-lg-12'>
                  <h1 class='h3 mb-2 text-gray-800'>Profile</h1>
                </div>
              </div>

              <div class='card shadow mb-4'>
                <div class='card-header py-3'>
                  <h4>Profile Settings</h4>
                </div>
                <form className='form-horizontal' onSubmit={onSubmit}>
                  <div className='card-body'>
                    <div className='form-group'>
                      <label className='col-md-3 offset-1 control-label'>
                        <b>Current Password</b>
                      </label>
                      <div className='col-md-9 offset-1'>
                        <div className='input-group'>
                          <input
                            type='password'
                            name='CurrentPassword'
                            className='form-control form-control-lg'
                            placeholder='Current Password'
                            onChange={onChange}
                            value={CurrentPassword}
                            required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text'>
                              <i className='fa fa-lock'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='col-md-3 offset-1 control-label'>
                        <b>New Password</b>
                      </label>
                      <div className='col-md-9 offset-1'>
                        <div className='input-group'>
                          <input
                            type='password'
                            name='NewPassword'
                            className='form-control form-control-lg'
                            placeholder='New Password'
                            onChange={onChange}
                            value={NewPassword}
                            required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text'>
                              <i className='fa fa-lock'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='col-md-3 offset-1 control-label'>
                        <b>Confirm Password</b>
                      </label>
                      <div className='col-md-9 offset-1'>
                        <div className='input-group'>
                          <input
                            type='password'
                            name='ConfirmPassword'
                            className='form-control form-control-lg'
                            placeholder='Confirm Password'
                            onChange={onChange}
                            value={ConfirmPassword}
                            required
                          />
                          <div className='input-group-append'>
                            <span className='input-group-text'>
                              <i className='fa fa-lock'></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer bg-white'>
                    <button
                      className='btn btn-primary btn-block btn-lg'
                      type='submit'
                    >
                      Update{' '}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
};

export default PasswordSetting;
