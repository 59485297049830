import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useReactToPrint } from 'react-to-print';

import AuthContext from '../../context/auth/authContext';
import InsuranceContext from '../../context/insurance/insuranceContext';

const PrintReport = (props) => {
  const authContext = useContext(AuthContext);
  const insuranceContext = useContext(InsuranceContext);
  const { current } = authContext;
  const { startDate, endDate } = insuranceContext;

  useEffect(() => {
    setTimeout(handlePrint, 1000);

    // eslint-disable-next-line
  }, []);

  let total =
    current &&
    current.reduce(function (tot, arr) {
      // return the sum with previous value
      return tot + arr.amount;

      // set initial value as 0
    }, 0);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  class ComponentToPrint extends React.Component {
    render() {
      return (
        <div style={{ fontSize: '25px' }}>
          <center id='top'>
            <div class='info'>
              <h5 className='text-dark' style={{ marginTop: '50px' }}>
                <b>Mahir Insurance Agency LTD</b>{' '}
              </h5>
            </div>
          </center>
          &nbsp; &nbsp;
          <div id='bot'>
            <div id='table'>
              <div>
                <h6 className='text-dark'>
                  <b>
                    Sales Report From{' '}
                    {new Date(startDate).toLocaleDateString('zh-Hans-CN')} To{' '}
                    {new Date(endDate).toLocaleDateString('zh-Hans-CN')}
                  </b>
                </h6>
              </div>
              <table className='table-striped'>
                <thead class='bg-dark'>
                  <td className='text-light'>
                    <b>CREATED</b>
                  </td>
                  <td className='text-light'>
                    <b>INSURED</b>
                  </td>
                  <td className='text-light'>
                    <b>POLICY NUMBER</b>
                  </td>

                  <td className='text-light'>
                    <b>INCEPTION DATE</b>
                  </td>
                  <td className='text-light'>
                    <b>EXPIRY DATE</b>
                  </td>
                  <td className='text-light'>
                    <b>MV REGISTRATION</b>
                  </td>
                  <td className='text-light'>
                    <b>TONNAGE</b>
                  </td>
                  <td className='text-light'>
                    <b>INSURER</b>
                  </td>
                  <td className='text-light'>
                    <b>DATE OF ISSUE</b>
                  </td>

                  <td className='text-light'>
                    <b>AMOUNT</b>
                  </td>
                  <td className='text-light'>
                    <b>CONTACT</b>
                  </td>
                  <td className='text-light'>
                    <b>STATUS</b>
                  </td>
                </thead>
                <tbody>
                  {current &&
                    current.map((current) => {
                      return (
                        <Fragment>
                          <tr>
                            <td className='text-dark'>
                              {new Date(current.createdAt).toLocaleDateString(
                                'zh-Hans-CN'
                              )}
                            </td>
                            <td className='text-dark'>
                              {current.insured_name}
                            </td>

                            <td className='text-dark'>
                              {current.policy_number}
                            </td>
                            <td className='text-dark'>
                              {' '}
                              {new Date(
                                current.inception_date
                              ).toLocaleDateString('zh-Hans-CN')}
                            </td>
                            <td className='text-dark'>
                              {' '}
                              {new Date(current.expiry_date).toLocaleDateString(
                                'zh-Hans-CN'
                              )}
                            </td>
                            <td className='text-dark'>
                              {current.mv_registration}
                            </td>
                            <td className='text-dark'>{current.tonnage}</td>
                            <td className='text-dark'>{current.insurer}</td>
                            <td className='text-dark'>
                              {' '}
                              {new Date(current.createdAt).toLocaleDateString(
                                'zh-Hans-CN'
                              )}
                            </td>
                            <td className='text-dark'>{current.amount}</td>
                            <td className='text-dark'>{current.phone}</td>
                            <td className='text-dark'>{current.status}</td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  <tr class='bg-dark'>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='text-light'>
                      <b>Total</b>
                    </td>

                    <td className='text-light'>
                      <b>{total.toLocaleString()}</b>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div style={{ display: 'none' }}>
      <ComponentToPrint ref={componentRef} />
    </div>
  );
};

export default PrintReport;
